export const dateRangeAmountOptions = [
  {
    displayName: '오늘',
    value: 'today',
  },
  {
    displayName: '어제',
    value: 'yesterday',
  },
  {
    displayName: '최근 3일',
    value: 'lastThreeDays',
  },
  {
    displayName: '최근 7일',
    value: 'lastSevenDays',
  },
  {
    displayName: '최근 한달',
    value: 'lastMonth',
  },
  {
    displayName: '전체',
    value: 'all',
  },
] as const;

export type DateRangeAmount = (typeof dateRangeAmountOptions)[number]['value'];
