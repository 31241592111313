import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { SearchForm, SearchOption } from '../../../types/form';
import { listItemTextTheme, selectTheme } from '../../../styles/customedMuiTheme';
import useSearchSelect from './useSearchSelect';
import { Dispatch, SetStateAction } from 'react';
import GridSection from '../../common/GridSection';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  field: string;
  selectOptions: readonly SearchOption[];
  defaultSelect?: string;
  gridColumn: `${string}/${string}`;
  gridRow?: number;
};

const SearchSelect = (props: Props) => {
  const { select, options, changeSelect } = useSearchSelect(props);

  return (
    <GridSection sx={{ gridColumn: props.gridColumn, gridRow: props.gridRow }}>
      <Select
        value={select}
        onChange={e => changeSelect(e.target.value)}
        sx={selectTheme.medium}
        fullWidth
      >
        {options?.map(option => (
          <MenuItem key={option.displayName} value={option.value.toString()}>
            <ListItemText sx={listItemTextTheme.medium} primary={option.displayName} />
          </MenuItem>
        ))}
      </Select>
    </GridSection>
  );
};

export default SearchSelect;
