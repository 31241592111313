import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import pageTitle from 'styles/pageTitle';
import bulkPackingAtom, {
  bulkPackingBoxAtom,
  bulkPackingDisplayWeightAtom,
  bulkPackingIsLoadingAtom,
  bulkPackingStepAtom,
} from '../../../../store/outbound/bulkPacking.recoil';
import { axiosV2 } from 'libs/common/fetcher';
import usePopup from 'hooks/usePopup';
import LoadingModal from '../components/LoadingModal';
import ToteScanLine from './components/ToteScanLine';
import BulkPackingBoxLine from './components/BulkPackingBoxLine';
import InvoiceNotice from './components/InvoiceNotice';
import Timeline from '../components/Timeline';
import borderedBox from 'styles/borderedBox';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import WeightMismatch from '../components/WeightMismatch';
import Dialog from '../../../../components/common/Popup/Dialog';
import { AxiosError } from 'axios';
import BulkPackingWeightLine from './components/BulkPackingWeightLine';
import {
  getPackingIdsToRequestWaybill,
  requestWaybill,
  updateBulkPackingStatusToRegistering,
} from './services';
import pLimit from 'p-limit';
import usePopupWindow from '../../../../hooks/usePopupWindow';

const completionStep = 3;
export const PICKING_GROUP_ID = 'picking-group-id';
export const FAILED_CARRIERS = 'failed-carriers';
export const BULK_PACKING_ID = 'bulk-packing-id';

type WeightResponse = {
  id: number;
  name: string;
  maxWeight: number;
};

export type WaybillRequest = {
  boxLength: number | undefined;
  gramWeight: string;
  checkWeight: boolean;
  boxWidth: number | undefined;
  boxHeight: number | undefined;
  boxCode: string | undefined;
  boxId: number | string | undefined;
};
const BulkPacking = () => {
  const [selectedStep, setSelectedStep] = useRecoilState(bulkPackingStepAtom);
  const resetBulkPacking = useResetRecoilState(bulkPackingAtom);
  const resetBulkPackingBox = useResetRecoilState(bulkPackingBoxAtom);
  const resetStep = useResetRecoilState(bulkPackingStepAtom);
  const resetLoading = useResetRecoilState(bulkPackingIsLoadingAtom);
  const [isLoading, setIsLoading] = useState(false);
  const bulkPacking = useRecoilValue(bulkPackingAtom);
  const { showErrorDialog } = usePopup();
  const { openPopupWindow } = usePopupWindow();
  const bulkPackingBox = useRecoilValue(bulkPackingBoxAtom);
  const setDisplayWeight = useSetRecoilState(bulkPackingDisplayWeightAtom);
  const [weight, setWeight] = React.useState('');
  const [showWeightMismatch, setShowWeightMismatch] = useState(false);

  useEffect(() => {
    return () => {
      useInitialization();
    };
  }, [resetBulkPacking, resetLoading, resetBulkPackingBox, resetStep]);

  const handleWeightSubmit = async (weight: string) => {
    setIsLoading(true);
    setWeight(weight);
    setDisplayWeight(weight);
    try {
      // 무게 오차 검증
      const diff = Math.abs(Number(bulkPacking?.estimatedWeight ?? 0) - Number(weight));

      if (diff > 1000) {
        setIsLoading(false);
        setShowWeightMismatch(true);
      } else {
        // 운송사 무게초과 검증
        checkOverMaxWeight(weight);
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ errorMessage: string }>;
      const errorMessage = axiosError?.response?.data?.errorMessage ?? '';
      showErrorDialog({
        title: '운송장 발행 실패',
        errorMessage: errorMessage,
        buttons: [{ text: '확인' }],
      });
      setWeight('');
    } finally {
      setIsLoading(false);
    }
  };

  const checkOverMaxWeight = async (weight: string) => {
    const bulkPackingId = bulkPacking?.bulkPackingId;
    const { data: validations } = await axiosV2.get<WeightResponse[]>(
      `/bulk-packings/${bulkPackingId}/carriers/weight-validation?gramWeight=${weight}`
    );

    const isOverMaxWeight = Boolean(validations.length);

    if (isOverMaxWeight) {
      showErrorDialog({
        title: '운송사 배송가능 무게초과 안내',
        errorMessage: validations
          .map(v => `${v.name} 배송 가능 무게 ${v.maxWeight}g / 측정 무게 ${weight}g`)
          .join('\n'),
        buttons: [
          {
            text: '운송사 변경',
            onClick: () => {
              localStorage.setItem(PICKING_GROUP_ID, bulkPacking?.pickingGroupSerialNumber ?? '');
              localStorage.setItem(
                FAILED_CARRIERS,
                JSON.stringify(validations.map(v => ({ ...v })))
              );
              localStorage.setItem(BULK_PACKING_ID, String(bulkPackingId) ?? '');
              openPopupWindow({ url: `gms/outbound/bulk-packing/carrier-change-for-failed` });
            },
          },
          { text: '송장발행중지', onClick: () => stopBulkPacking() },
        ],
      });

      return;
    } else {
      // 운송장 요청
      setIsLoading(true);
      onRequestBulkWaybills(weight);
    }
  };

  const updateBulkPackingStatus = async (bulkPackingId: number, waybillRequest: WaybillRequest) => {
    const requestUpdateStatus = await updateBulkPackingStatusToRegistering(
      bulkPackingId,
      waybillRequest
    );
    if (requestUpdateStatus?.status !== 200) {
      setIsLoading(false);
      showErrorDialog({
        title: '운송장 요청 실패',
        errorMessage: requestUpdateStatus?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    return requestUpdateStatus?.status;
  };

  const onRequestBulkWaybills = async (weight: string) => {
    const waybillRequest: WaybillRequest = {
      gramWeight: weight,
      boxCode: bulkPackingBox?.name,
      checkWeight: true,
      boxId: bulkPackingBox?.id,
      boxWidth: bulkPackingBox?.width,
      boxLength: bulkPackingBox?.length,
      boxHeight: bulkPackingBox?.height,
    };
    const bulkPackingId = bulkPacking?.bulkPackingId ?? -1;
    const newVar = await updateBulkPackingStatus(bulkPackingId, waybillRequest);
    if (newVar !== 200) {
      return;
    }
    const response = await getPackingIdsToRequestWaybill(bulkPackingId);
    if (response?.status !== 200) {
      setIsLoading(false);
      showErrorDialog({
        title: '운송장 요청 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      return;
    }
    const data1 = response?.data;
    const limit = pLimit(10);
    const completeBulkPackingByPackingId: number[] = data1?.map((item: { packingId: number }) =>
      limit(() => onRequestWaybill(bulkPackingId, item['packingId'], waybillRequest))
    );
    await Promise.all(completeBulkPackingByPackingId);
    const newVar1 = await updateBulkPackingStatus(bulkPackingId, waybillRequest);
    if (newVar1 === 200) {
      setIsLoading(false);
      setSelectedStep(selectedStep + 1);
    }
  };

  const onRequestWaybill = async (
    bulkPackingId: number,
    packingId: number,
    waybillRequest: WaybillRequest
  ) => {
    const { status } = await requestWaybill(bulkPackingId, packingId, waybillRequest);
    return status;
  };

  const useInitialization = () => {
    resetBulkPacking();
    resetBulkPackingBox();
    resetStep();
    resetLoading();
    setWeight('');
    setDisplayWeight('');
  };

  const handleClose = () => {
    setShowWeightMismatch(false);
  };

  const stopBulkPacking = async () => {
    const bulkPackingId = bulkPacking?.bulkPackingId;
    await axiosV2.patch(`/bulk-packings/${bulkPackingId}/stop`);
    useInitialization();
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        대량/기증 포장 송장발행 관리
      </Typography>
      <Box
        sx={{
          ...borderedBox,
          flex: 1,
          height: '90vh',
          padding: '32px',
        }}
      >
        <Timeline currentStep={selectedStep} sx={{ padding: 0, margin: 0 }}>
          <ToteScanLine />
          <BulkPackingBoxLine />
          <BulkPackingWeightLine // step 2
            onSubmit={handleWeightSubmit}
          />
        </Timeline>
        {selectedStep === completionStep && (
          <InvoiceNotice
            bulkPackingId={bulkPacking?.bulkPackingId ?? -1}
            reset={() => useInitialization()}
          />
        )}
        <LoadingModal isLoading={isLoading} message="송장 발행 중..." />
      </Box>
      <Dialog
        onClose={handleClose}
        open={showWeightMismatch}
        buttons={[
          {
            text: '포장중지',
            onClick: () => stopBulkPacking(),
          },
          {
            text: '계속 포장진행',
            onClick: () => checkOverMaxWeight(weight),
          },
        ]}
      >
        <WeightMismatch estimated={bulkPacking?.estimatedWeight ?? 0} measured={Number(weight)} />
      </Dialog>
    </Page>
  );
};

export default BulkPacking;
