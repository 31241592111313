import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { GridEventListener, GridEvents } from '@mui/x-data-grid-pro';
import Page from 'components/common/Layout/Page';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../../consts/common/pageAndSize';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import FormModal from '../../../../../components/common/FormModal';
import convertUser from '../../../../../libs/common/convertUser';
import useConditionalSWR from '../../../components/useConditionalSwr';
import gridColDef from './gridColDef';
import CompanyForm from './components/CompanyForm';
import SearchContainer from '../../../../../components/Search/SearchContainer';
import GridSection from '../../../../../components/common/GridSection';
import { SearchSelect } from '../../../../../components/Search/SearchSelect';
import SearchButtonGroup from '../../../../../components/Search/SearchButtonGroup';
import useCompanySearch from './hooks/useCompanySearch';
import { defaultOption } from '../../../../../consts/common/defaultOption';
import { reloadOutletAtom } from '../../../../../store/common/menu.recoil';
import SearchLabel from '../../../../../components/Search/SearchLabel';

export type Company = {
  rowIndex: number;
  id: number;
  companyId: number;
  registrationNumber: string;
  code: string;
  name: string;
  isActive: string;
  updatedUsername: string;
  updatedUserLoginId: string;
  updatedAt: string;
};

export type CompanyResponse = {
  content: Company[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
};

const gridTemplateColumns = [
  '80px',
  'minmax(45px, 1fr)',
  'minmax(45px, 1fr)',
  '80px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const Companies = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Company>();

  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const activeTypeOptions = refCodeOptions?.activeType || [];
  const setReloadOutlet = useSetRecoilState(reloadOutletAtom);

  const { updateForm, handleSearch } = useCompanySearch();

  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
  });

  const { data, mutate, isValidating } = useConditionalSWR<CompanyResponse>(
    [`/companies/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const makeRowIndex = (data: CompanyResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Company, index: number) => ({
      ...item,
      id: item.companyId,
      rowIndex: totalElements - size * number - index,
      updatedUser: convertUser(item.updatedUsername, item.updatedUserLoginId),
    }));
  };

  const handleSearchClick = () => {
    const updatedForm = handleSearch();

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
  };

  const handleInitClick = () => {
    setReloadOutlet(new Date().getMilliseconds());
  };

  const handleRowClick: GridEventListener<GridEvents.rowClick> = params => {
    params.id && setIsModalOpen(true);

    setSelectedRow(data?.content?.find(item => item.companyId === params.id));
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(undefined);
  };

  const handleCreate = () => {
    setIsModalOpen(true);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        회사 관리
      </Typography>
      <SearchContainer gridTemplateColumns={gridTemplateColumns}>
        <SearchLabel label={'사용여부'} gridColumn={'10/11'} />
        <SearchSelect
          field="isActive"
          updateForm={updateForm}
          selectOptions={[defaultOption, ...activeTypeOptions]}
          gridColumn={'11/13'}
        />
        <SearchButtonGroup
          gridRow={2}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
        />
      </SearchContainer>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            회사 목록
          </Typography>
          <Box>
            <Button
              sx={listBoardTheme.headerButton}
              variant="outlined"
              suppressHydrationWarning
              onClick={handleCreate}
            >
              회사 등록
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={data?.totalElements ?? 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleRowClick}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={isModalOpen}
        title={selectedRow ? '회사 수정' : '회사 등록'}
        onClose={onCloseModal}
      >
        <CompanyForm selectedRow={selectedRow} onClose={onCloseModal} onMutate={mutate} />
      </FormModal>
    </Page>
  );
};

export default Companies;
