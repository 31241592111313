import * as XLSX from 'xlsx';

type ExcelCreationDto = {
  shippingId: number;
  warehouseName: string;
  shippingStatus: string;
  shippingSerialNumber: string;
  deliveryId: string;
  deliverySeq: string;
  pathNumber: string;
  pathName: string;
  sku: number;
  totalQty: number;
  totalWeight: number;
  volumeWeight: number;
  width: number;
  length: number;
  height: number;
  recommendBox: string;
  carrierName: string;
  hasTube: string;
  pickingGroupSerialNumber: string;
  shippingOrderSerialNumber: string;
  updatedUser: string;
  updatedAt: string;
};

export function createExcelFile(content: ExcelCreationDto[]) {
  try {
    const translatedData = content.map(item => ({
      'ID': item.shippingId,
      '창고': item.warehouseName,
      '출고상태': item.shippingStatus,
      '출고ID': item.shippingSerialNumber,
      '배송번호': item.deliveryId,
      '배송ID': item.deliverySeq,
      '포장경로': item.pathNumber,
      '작업공간': item.pathName,
      '품목수량': item.sku,
      '아이템수량': item.totalQty,
      '실제중량무게(g)': item.totalWeight,
      '실제부피무게(g)': item.volumeWeight,
      '가로(mm)': item.width,
      '세로(mm)': item.length,
      '높이(mm)': item.height,
      '배송방식': item.carrierName,
      '지관통여부': item.hasTube,
      '집품그룹ID': item.pickingGroupSerialNumber,
      '출고지시ID': item.shippingOrderSerialNumber,
      '변경자': item.updatedUser,
      '변경일자': item.updatedAt,
    }));

    const worksheet = XLSX.utils.json_to_sheet(translatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '걍로목록');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `출고건별 포장경로 목록_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
  } catch (error) {
    console.error('엑셀 파일 생성 중 오류 발생:', error);
  }
}
