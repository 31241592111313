import type { GridColDef } from '@mui/x-data-grid-pro';
import Link from '@mui/material/Link';
import { convertCarrierId } from '../../../../libs/common/convertCarrier';
import { carriersAtom } from '../../../../store/outbound/carrier.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { useRecoilValue } from 'recoil';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import usePopupWindow from '../../../../hooks/usePopupWindow';

const gridColumns = () => {
  const carriers = useRecoilValue(carriersAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const statusOptions = refCodeOptions?.inspectionStatus || [];
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const { openPopupWindow } = usePopupWindow();

  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '검수상태',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => statusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'inspectionHistory',
      headerName: '검수오류내역',
      minWidth: 180,
      flex: 3,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: params => (
        <Link
          onClick={() =>
            openPopupWindow({ url: `/gms/outbound/inspections/${params.row.id}/errors` })
          }
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'pickingGroupType',
      headerName: '집품구분',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'createdAt',
      headerName: '집품완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'inspectionCompletedAt',
      headerName: '검수완료일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'toteBarcode',
      headerName: '토트',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: value => {
        return value.value.join(', ');
      },
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 240,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isInspectionSkipped',
      headerName: '검수필요여부',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value ? 'N' : 'Y';
      },
    },
    {
      field: 'skuCount',
      headerName: '품목수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'totalQuantity',
      headerName: '아이템수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'country',
      headerName: '배송국가',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'realWeight',
      headerName: '실제중량무게(g)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'estimatedVolumeWeight',
      headerName: '예상부피무게(g)',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedUser',
      headerName: '작업자',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'updatedAt',
      headerName: '작업일시',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
