import React from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { COLORS } from '../../../../../consts/common/colors';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import { LabelCell, ValueCell } from '../../../../../components/common/Tables';
import refCodeOptionsAtom from '../../../../../store/outbound/refCode.recoil';
import { BulkPacking } from '../../../../../store/outbound/bulkPacking.recoil';
import ListTable, { Value } from '../../components/ListTable';
import { separateArrow } from '../../inspection/components/ItemListLine/utils';

type Tote = {
  toteBarcode: string;
  totalSkuCount: number;
  totalQuantity: number;
};

type Product = {
  itemId: number;
  barcode: string;
  code: string;
  itemName: string;
  imageUrl: string;
  option?: string;
  orderQuantity: number;
  remark?: string;
};

const BulkPackingTable = (props: { bulkPacking: BulkPacking }) => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];

  const {
    bulkPacking: {
      pickingGroupSerialNumber,
      pickingGroupType,
      sameShippingCount,
      totalWeightOfShipping,
      shippingItems,
      totes,
    },
  } = props;

  const isDonation = pickingGroupType?.toLowerCase() === 'donation';

  return (
    <Stack>
      <TableContainer component={Paper} sx={{ boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
        <Table>
          <TableBody>
            <TableRow>
              <LabelCell>집품그룹ID</LabelCell>
              <ValueCell>{pickingGroupSerialNumber}</ValueCell>
              <LabelCell>집품구분</LabelCell>
              <ValueCell>
                {pickingGroupTypeOptions.find(v => v.value === pickingGroupType)?.displayName}
              </ValueCell>
            </TableRow>
            <TableRow>
              <LabelCell>출고건수</LabelCell>
              <ValueCell>{sameShippingCount?.toLocaleString()}</ValueCell>
              <LabelCell>{isDonation ? '전체 실제중량무게(g)' : '1박스 실제중량무게(g)'}</LabelCell>
              <ValueCell>{totalWeightOfShipping?.toLocaleString()}</ValueCell>
            </TableRow>
            <TableRow>
              <LabelCell>전체품목수량</LabelCell>
              <ValueCell>{shippingItems?.length}</ValueCell>
              <LabelCell>전체 아이템수량</LabelCell>
              <ValueCell>
                {calculateTotalItemQuantity(shippingItems, sameShippingCount, isDonation)}
              </ValueCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant="h6"
        sx={{
          margin: '14px 0 12px 0',
          ...subTitleStyle,
        }}
      >
        [집품그룹단위 토트정보]
      </Typography>
      <ListTable
        rows={makeTotes(totes)}
        columns={toteColumns}
        sx={{ maxHeight: '200px' }}
        hideFooter={true}
      />

      <Typography
        variant="h6"
        sx={{
          margin: '24px 0 12px 0',
          ...subTitleStyle,
        }}
      >
        {isDonation ? '[집품그룹단위 아이템정보]' : '[1박스 포장단위 아이템정보]'}
      </Typography>
      <ListTable
        rows={makeItems(shippingItems)}
        columns={getItemColumns(isDonation)}
        total={shippingItems?.reduce((total, { orderQuantity }) => total + orderQuantity, 0)}
        sx={{ maxHeight: '400px' }} // 현장 모니터 해상도에 맞게 조절 필요
      />
    </Stack>
  );
};

const subTitleStyle = {
  fontWeight: 700,
  color: COLORS.grey,
};

const toteColumns = [
  { headerName: 'NO', field: 'order', width: '10%' },
  { headerName: '토트 바코드', field: 'toteBarcode', width: '56%' },
  { headerName: '품목수량', field: 'totalSkuCount', width: '17%' },
  { headerName: '아이템수량', field: 'totalQuantity', width: '17%' },
];

const getItemColumns = (isDonation: boolean) => [
  { headerName: 'NO', field: 'order', width: '10%' },
  { headerName: '바코드', field: 'barcode', width: '10%' },
  { headerName: '아이템코드', field: 'code', width: '15%' },
  {
    headerName: '이미지',
    field: 'imageUrl',
    width: '5%',
    renderCell: (url: Value) => {
      const finalUrl = getFinalUrl(url as string);
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}>
          <AutoContainImage src={finalUrl.toString()} imageContainerSize={50} />
        </Box>
      );
    },
  },
  {
    headerName: '아이템명',
    field: 'itemName',
    width: '45%',
    renderCell: (itemName: Value) => {
      const [item, remark] = (itemName as string).split(separateArrow);
      return (
        <p style={{ textAlign: 'start' }}>
          <span>{item}</span>
          <span style={{ color: 'red' }}>{remark ? separateArrow + remark : ''}</span>
        </p>
      );
    },
  },
  {
    headerName: isDonation ? '아이템총수량' : '아이템수량',
    field: 'orderQuantity',
    width: '15%',
  },
];

export default BulkPackingTable;

const makeTotes = (totes: Tote[]) =>
  totes.map((tote, i) => ({
    ...tote,
    id: tote.toteBarcode,
    order: i + 1,
  }));

const makeItems = (items: Product[]) =>
  items.map((product, i) => ({
    ...product,
    id: product.code,
    order: i + 1,
    itemName: product.itemName + `${product.remark ? separateArrow + product.remark : ''}`,
  }));

const calculateTotalItemQuantity = (
  items: Product[],
  shippingQuantity: number,
  isDonation: boolean
) => {
  if (!Array.isArray(items) || isNaN(shippingQuantity)) return;

  if (isDonation) {
    return items.reduce((total, { orderQuantity }) => total + orderQuantity, 0)?.toLocaleString();
  }

  return (
    items.reduce((total, { orderQuantity }) => total + orderQuantity, 0) * shippingQuantity
  )?.toLocaleString();
};
