import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import { axiosV2 } from 'libs/common/fetcher';
import { errorType } from '../../../../../types/common';
import { Payload } from '../index.page';

export type createPickingGroupRequest = {
  warehouseId: number | string;
};

export const fetchPickingGroup = async (requestBody: createPickingGroupRequest) => {
  try {
    return await axiosV2.post(`/picking-groups`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`집품그룹 생성 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const postPickingGroups = async (payload: Payload) => {
  try {
    return await axiosV2.post(
      '/picking-groups/same-shippings',
      { ...payload.request },
      { params: { ...payload.params, sort: 'id,desc' } }
    );
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`집품그룹 가져오기 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
