import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { COLORS } from '../../../../../consts/common/colors';
import usePopup from '../../../../../hooks/usePopup';
import { updateConveyorPaths } from '../services';
import SaveButtonGroup from '../../../components/SaveButtonGroup';
import * as XLSX from 'xlsx';
import { REVERSE_CONVEYOR_PATH } from '../../../../../consts/info/conveyorPath';

type Props = {
  onCloseClick: () => void;
  onMutate: () => void;
};

const ExcelUpload = (props: Props) => {
  const { onCloseClick, onMutate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { showSnackbar, showAlert, showErrorDialog } = usePopup();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      showAlert({
        message: '파일을 선택해주세요.',
      });
      return;
    }

    setIsLoading(true);

    const excelData = await parseExcelFileToJson(file);
    const request = excelData.map(item => {
      return {
        shippingId: item.shippingId,
        path: REVERSE_CONVEYOR_PATH[item.path as keyof typeof REVERSE_CONVEYOR_PATH],
      };
    });
    const response = await updateConveyorPaths(request);
    if (response?.status === 200) {
      setIsLoading(false);
      showSnackbar({ message: '포장 경로가 변경되었습니다.', severity: 'success' });
      await onMutate();
      setIsLoading(false);
      onCloseClick();
    } else {
      showErrorDialog({
        title: '엑셀 업로드 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
      setIsLoading(false);
    }
  };
  const modalWidth = 600;
  return (
    <Box width={modalWidth}>
      <Box sx={styles.container}>
        <input
          accept=".xlsx,.xls"
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
      </Box>
      <Box>
        <SaveButtonGroup
          onSaveClick={handleUpload}
          onCloseClick={onCloseClick}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

const parseExcelFileToJson = async (
  file: File
): Promise<{ shippingId: number; path: string }[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as string[][];

      const header = json[0];
      const rows = json.slice(1);

      const idIndex = header.indexOf('ID');
      const pathIndex = header.indexOf('포장경로');

      if (idIndex === -1 || pathIndex === -1) {
        reject(new Error('엑셀 헤더에 "ID" 또는 "포장경로" 열이 없습니다.'));
        return;
      }

      const payload = rows
        .map(row => ({
          shippingId: Number(row[idIndex]),
          path: row[pathIndex],
        }))
        .filter(item => !isNaN(item.shippingId) && item.path);

      resolve(payload);
    };

    reader.onerror = () => {
      reject(new Error('파일을 읽을 수 없습니다.'));
    };

    reader.readAsArrayBuffer(file);
  });
};

export default ExcelUpload;

const styles = {
  container: {
    border: `1px solid ${COLORS.darkLine}`,
  },
  select: {
    height: '32px',
  },
};
