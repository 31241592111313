import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { GridCellEditCommitParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import Page from '../../../../../../components/common/Layout/Page';
import TightDataGridSum from '../../../../../../components/common/TightDataGridSum';
import refCodeOptionsAtom from '../../../../../../store/outbound/refCode.recoil';
import TableHorizontal, { TableCell, TableRow } from '../../../../components/TableHorizontal';
import { getFinalUrl } from '../../../../../../consts/outbound/imageUrl';
import usePopup from '../../../../../../hooks/usePopup';
import pageTitle from '../../../../../../styles/pageTitle';
import { inputTheme, listBoardTheme } from '../../../../../../styles/customedMuiTheme';
import gridColDef from './gridColDef';
import {
  createReceiving,
  RequestBodyForCreateReceiving,
  getExpectedReceivingDetails,
} from './services';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import useReceiveItemsFromSearch, { Item } from '../../../../../../hooks/useReceiveItemsFromSearch';
import warehouseAtom from '../../../../../../store/outbound/warehouse.recoil';
import ImageZoomModal from '../../../../../../components/ImageZoomModal';
import usePopupWindow from '../../../../../../hooks/usePopupWindow';
import { getItemLocations, createExcelFile } from './services/exceldownload';

type InboundExpectedReceivingDetailContent = {
  itemId: number | string;
  barcode: string;
  offlineBarcode: string;
  itemCode: string;
  imageUrl: string;
  itemName: string;
  quantity: number;
  cost: number;
  totalCost: number;
  quantityOfReceiving: number;
  costOfReceiving: number;
  totalCostOfReceiving: number;
};

type InboundExpectedReceivingDetail = {
  purchaseOrderId: number;
  purchaseOrderItems: InboundExpectedReceivingDetailContent[];
  receivingETA: string;
  purchaseOrderName: string;
  supplierName: string;
  supplierId: number;
  status: string;
  receivingType: string;
};

const ExpectedReturnReceivingDetails = () => {
  const {
    state: { purchaseOrderId },
  } = useLocation();

  const globalWarehouse = useRecoilValue(warehouseAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const purchaseOrderStatusOptions = refCodeOptions?.gmsRegistedStatus || [];

  const [data, setData] = useState<InboundExpectedReceivingDetail>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [receivedDate, setReceivedDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [selectedItemIds, setSelectedItemIds] = useState<(number | string)[]>([]);
  const { showErrorDialog, showAlert, showDialog } = usePopup();
  const { openPopupWindow } = usePopupWindow();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getExpectedReceivingDetails(purchaseOrderId);
      if (response?.status === 200) {
        setData(makeDataWithRowIndex(initReceivingQuantityAndCostOfData(response.data)));
      } else {
        showErrorDialog({
          title: '입고반품예정 상세 조회 실패',
          errorMessage: response?.data?.errorMessage,
          buttons: [{ text: '확인' }],
        });
      }
    };

    fetchData();
  }, []);

  const handleAddItems = () => {
    updateItems([]); // 새로운 아이템을 가져오기 전에 data에 넣은 items를 비워서 아이템 중복을 방지.
    openPopupWindow({
      url: `gms/inbound/expect-receiving/details/search-items`,
      features: {
        width: 1300,
        height: 800,
      },
      body: { warehouseId: globalWarehouse },
    });
  };
  // openNewWindowWithFeature 을 통해 선택한 아이템을 받아옴.
  const { items, updateItems } = useReceiveItemsFromSearch();

  useEffect(() => {
    if (!data || items.length === 0) return;
    const newData = addSelectedItemsToData(data, items);
    setData(makeDataWithRowIndex(newData));
  }, [items]);

  const handleReceivedDateChange = (date: Dayjs | null) => {
    setReceivedDate(date);
  };

  const topBoards = [
    { label: '발주서명', value: data?.purchaseOrderName },
    { label: '공급업체', value: data?.supplierName },
    {
      label: '입고반품예정일',
      value: data?.receivingETA,
    },
    {
      label: '입고상태',
      value: data?.status
        ? purchaseOrderStatusOptions.find(item => item.value === data.status)?.displayName
        : '',
    },
    {
      label: '입고반품일',
      value: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']} sx={styles.dateContainer}>
            <DemoItem>
              <DatePicker
                value={receivedDate}
                onChange={handleReceivedDateChange}
                format={'YYYY-MM-DD'}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      ),
    },
  ];

  const handleGenerate = async () => {
    setIsLoading(true);
    if (selectedItemIds.length === 0) {
      showAlert({ message: '입고할 아이템을 선택해 주세요.' });
      setIsLoading(false);
      return;
    }

    const selectedItems = data?.purchaseOrderItems.filter(item =>
      selectedItemIds.includes(item.itemId)
    );

    if (!data || !data.purchaseOrderId || !data.supplierId || !selectedItems) {
      setIsLoading(false);
      return;
    }

    const items = selectedItems.map(item => ({
      itemId: item.itemId,
      quantity: item.quantityOfReceiving,
      cost: item.costOfReceiving,
    }));

    const requestBody: RequestBodyForCreateReceiving = {
      receivingType: data.receivingType,
      purchaseOrderId: data.purchaseOrderId,
      supplierId: data.supplierId,
      receivedDate: receivedDate?.toISOString() ?? '-',
      warehouseId: Number(globalWarehouse),
      itemsToReceiving: items,
    };

    const response = await createReceiving(requestBody);
    if (response?.status === 200) {
      showDialog({
        message: '입고등록이 완료되었습니다.',
        buttons: [{ text: '확인', onClick: () => window.history.back() }],
      });
    } else {
      showErrorDialog({
        title: '입고등록 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsLoading(false);
  };

  const handleSelectionModelChange = (ids: GridSelectionModel) => {
    setSelectedItemIds(ids as number[]);
  };

  const handleCellEditCommit = (params: GridCellEditCommitParams) => {
    data && setData(updateDataByInput(data, params));
  };

  const handleGoConfirmListBack = () => {
    window.history.back();
  };

  const handleExcelDownload = async () => {
    if (!data?.purchaseOrderItems || data.purchaseOrderItems.length === 0) {
      showAlert({ message: '다운로드할 아이템이 없습니다.' });
      return;
    }

    const goodsIds = data.purchaseOrderItems.map(item => item.itemId);
    const response = await getItemLocations(goodsIds, globalWarehouse);

    if (response?.status === 200 && response.data) {
      const result = createExcelFile(response.data);
      if (!result) {
        showAlert({ message: '엑셀 파일 생성 중 오류가 발생했습니다.' });
      }
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.data?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        입고반품 등록 상세
      </Typography>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <TableHorizontal>
          <TableRow height={'76px'}>
            {topBoards.map(board => (
              <TableCell
                key={board.label}
                label={board.label}
                value={board.value}
                valueSx={{ flex: 1, p: 1 }}
              />
            ))}
          </TableRow>
        </TableHorizontal>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            입고 아이템 목록
          </Typography>
        </Box>
        <Box sx={styles.buttonGroup}>
          <Button variant="outlined" onClick={handleExcelDownload}>
            반품아이템 로케이션 엑셀 다운로드
          </Button>
        </Box>
        <TightDataGridSum
          getRowId={row => row.itemId}
          rows={addSumRowToItems(data?.purchaseOrderItems)}
          columns={gridColDef}
          hideFooter
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selectedItemIds}
          onSelectionModelChange={handleSelectionModelChange}
          onCellEditCommit={handleCellEditCommit}
          onCellClick={({ field, row }) => {
            if (field === 'imageUrl') setImageUrl(getFinalUrl(row.imageUrl));
          }}
          hasSearched={true}
        />
        <Box sx={styles.buttonGroup}>
          <Button variant="outlined" onClick={handleGoConfirmListBack} sx={{ width: 110 }}>
            목록
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleGenerate}
            loading={isLoading}
            sx={{ width: 110 }}
            disabled={data?.status === 'CREATED'}
          >
            저장
          </LoadingButton>
        </Box>
        <ImageZoomModal imageUrl={imageUrl} onClose={() => setImageUrl('')} />
      </Stack>
    </Page>
  );
};

export default ExpectedReturnReceivingDetails;

const initReceivingQuantityAndCostOfData = (data: InboundExpectedReceivingDetail) => ({
  ...data,
  purchaseOrderItems: data.purchaseOrderItems.map(
    (item: InboundExpectedReceivingDetailContent) => ({
      ...item,
      quantityOfReceiving: item.quantity,
      costOfReceiving: item.cost,
      totalCostOfReceiving: item.quantity * item.cost,
    })
  ),
});

const makeDataWithRowIndex = (data: InboundExpectedReceivingDetail) => {
  return {
    ...data,
    purchaseOrderItems: data?.purchaseOrderItems.map(
      (item: InboundExpectedReceivingDetailContent, index: number) => ({
        ...item,
        rowIndex: index + 1,
        totalCost: item.quantity * item.cost,
        quantityOfReceiving: item.quantityOfReceiving,
        costOfReceiving: item.costOfReceiving,
        totalCostOfReceiving: item.totalCostOfReceiving,
      })
    ),
  };
};

const updateDataByInput = (
  data: InboundExpectedReceivingDetail,
  params: GridCellEditCommitParams
): InboundExpectedReceivingDetail => {
  return {
    ...data,
    purchaseOrderItems: data.purchaseOrderItems.map(item => {
      if (item.itemId === params.id) {
        const quantityOfReceiving =
          params.field === 'quantityOfReceiving' ? Number(params.value) : item.quantityOfReceiving;
        const costOfReceiving =
          params.field === 'costOfReceiving' ? Number(params.value) : item.costOfReceiving;
        const totalCostOfReceiving = quantityOfReceiving * costOfReceiving;

        return {
          ...item,
          quantityOfReceiving,
          costOfReceiving,
          totalCostOfReceiving,
        };
      }

      return item;
    }),
  };
};

const addSelectedItemsToData = (
  data: InboundExpectedReceivingDetail,
  items: Item[]
): InboundExpectedReceivingDetail => {
  const filteredItems = items.filter(
    item => !data?.purchaseOrderItems.some(poItem => poItem.itemId === item.goodsId)
  );

  const addedItems: InboundExpectedReceivingDetailContent[] = [
    ...filteredItems.map(item => ({
      itemId: item.goodsId,
      barcode: item.barcode,
      offlineBarcode: item.offlineBarcode,
      itemCode: item.itemCode,
      imageUrl: item.itemImageUrl,
      itemName: item.itemName,
      quantity: 0,
      cost: item.supplyPrice,
      totalCost: 0,
      quantityOfReceiving: 0,
      costOfReceiving: 0,
      totalCostOfReceiving: 0,
    })),
  ];

  return {
    ...data,
    purchaseOrderItems: addedItems.concat(data?.purchaseOrderItems ?? []),
  };
};

const addSumRowToItems = (items: InboundExpectedReceivingDetailContent[] | undefined) => {
  if (items === undefined) return [];

  const summery = {
    rowIndex: 'total',
    itemId: '',
    barcode: '',
    offlineBarcode: '',
    itemCode: '',
    imageUrl: '',
    itemName: '',
    quantity: items.reduce((acc, cur) => acc + cur.quantity, 0) ?? 0,
    cost: items.reduce((acc, cur) => acc + cur.cost, 0) ?? 0,
    totalCost: items.reduce((acc, cur) => acc + cur.totalCost, 0) ?? 0,
    quantityOfReceiving: items.reduce((acc, cur) => acc + cur.quantityOfReceiving, 0) ?? 0,
    costOfReceiving: items.reduce((acc, cur) => acc + cur.costOfReceiving, 0) ?? 0,
    totalCostOfReceiving: items.reduce((acc, cur) => acc + cur.totalCostOfReceiving, 0) ?? 0,
  } as InboundExpectedReceivingDetailContent;

  return items.concat(summery);
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ml: 1,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'right',
    gap: '8px',
    marginTop: 2,
  },
  dateContainer: {
    ...inputTheme.medium,
    overflow: 'hidden',
    p: '0px',
  },
};
