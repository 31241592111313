import { styled } from '@mui/material/styles';
import MuiTableCell from '@mui/material/TableCell';
import { COLORS } from '../../consts/common/colors';

export const LabelCell = styled(MuiTableCell)(({ theme, width }) => ({
  backgroundColor: COLORS.bgLight,
  color: theme.palette.common.black,
  fontSize: 13,
  minWidth: width || 200,
}));

export const ValueCell = styled(MuiTableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 13,
  width: '40%',
}));
