import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { SearchForm, SearchOption } from '../../../types/form';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  selectOptions: readonly SearchOption[];
  defaultSelect?: string;
};

const useSearchTextWithSelect = (props: Props) => {
  const { updateForm, selectOptions, defaultSelect } = props;

  const [select, setSelect] = useState<string>('');
  const [text, setText] = useState<string>('');

  useEffect(() => {
    setSelect(defaultSelect || selectOptions[0].value);
    setText('');
  }, [defaultSelect, selectOptions[0].value]);

  const changeSelect = (value: string) => {
    updateForm(form => swapFormKey({ form, fromKey: select, toKey: value }));
    setSelect(value);
  };

  const changeText = (value: string) => {
    updateForm(form => ({ ...form, [select]: value }));
    setText(value);
  };

  return {
    select,
    options: selectOptions,
    changeSelect,
    text,
    changeText,
  };
};

export default useSearchTextWithSelect;

type Params = {
  form: SearchForm;
  fromKey: string;
  toKey: string;
};

const swapFormKey = ({ form, fromKey, toKey }: Params) => {
  const updatedForm = { ...form };
  updatedForm[toKey] = updatedForm[fromKey];
  delete updatedForm[fromKey];

  return updatedForm;
};
