import * as XLSX from 'xlsx';
import { PickupHistory, statusOptions } from '../index.page';
import convertUser from '../../../../../libs/common/convertUser';

export type PickupHistoryExcelDto = {
  orderNo: number;
  orderSeq: string;
  receivingConfirmedDate: string;
  requestedPickUpDate: string;
  status: string;
  updateDate: string;
  worker: string;
  zone: string;
};

export const createExcelFile = (history: PickupHistoryExcelDto[]) => {
  try {
    const translatedData = history.map(item => ({
      '수령신청일자': item.requestedPickUpDate,
      '수령상태': item.status,
      '배송ID': item.orderSeq,
      '배송번호': item.orderNo,
      '수령zone': item.zone,
      '작업자': item.worker,
      '작업일': item.updateDate,
      '수령확확인완료일시': item.receivingConfirmedDate,
    }));

    const worksheet = XLSX.utils.json_to_sheet(translatedData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '픽업 히스토리');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `픽업히스토리_${new Date().toISOString().split('T')[0]}.xlsx`; // 파일명 설정
    link.click();
    return true;
  } catch (error) {
    console.error('엑셀 파일 생성 중 오류 발생:', error);
    return false;
  }
};
