import { Dispatch, SetStateAction } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { SearchForm, SearchOption } from '../../../types/form';
import { listItemTextTheme, selectTheme } from '../../../styles/customedMuiTheme';
import GridSection from '../../common/GridSection';
import useSearchMultiSelect from './useSearchMultiSelect';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  field: string;
  selectOptions: readonly SearchOption[];
  defaultValues?: string[];
  gridColumn: `${string}/${string}`;
  gridRow?: number;
};

const SearchMultiSelect = (props: Props) => {
  const { multiSelects, selectOptions, renderMultiSelects, changeMultiSelects } =
    useSearchMultiSelect(props);

  return (
    <GridSection gridColumn={props.gridColumn} gridRow={props.gridRow}>
      <Select
        value={multiSelects}
        renderValue={value => renderMultiSelects(selectOptions, value)}
        onChange={e => changeMultiSelects(e.target.value)}
        multiple
        fullWidth
        sx={selectTheme.medium}
      >
        {selectOptions?.map(option => (
          <MenuItem key={option.value} value={option.value} sx={{ p: 0, pr: 1 }}>
            <Checkbox checked={multiSelects?.includes(option.value)} sx={{ height: 23 }} />
            <ListItemText primary={option.displayName} sx={listItemTextTheme.medium} />
          </MenuItem>
        ))}
      </Select>
    </GridSection>
  );
};

export default SearchMultiSelect;
