import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SearchForm, SearchOption } from '../../../types/form';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  field: string;
  selectOptions: readonly SearchOption[];
  defaultValues?: string[];
};

const useSearchMultiSelect = (props: Props) => {
  const { updateForm, field, selectOptions, defaultValues = ['ALL'] } = props;

  const [multiSelects, setMultiSelects] = useState<string[]>([]);

  const hasOptions = selectOptions?.length > 1; // defaultOption 제외
  const hasDefaultValues = defaultValues?.length > 0;
  useEffect(() => {
    if (defaultValues.includes('ALL')) {
      setMultiSelects(selectOptions?.map(option => option.value));
    } else {
      setMultiSelects(defaultValues);
      updateForm(form => ({ ...form, [field]: defaultValues.join(', ') }));
    }
  }, [hasDefaultValues, hasOptions]);

  const renderMultiSelects = (options: readonly SearchOption[], values: string[]) => {
    if (values.length === 0 || values.includes('ALL')) {
      return '전체';
    }

    return values
      .map(value => getDisplayName(options, value))
      .reverse()
      .join(', ');
  };

  const changeMultiSelects = (value: string | string[]) => {
    if (typeof value === 'string') {
      console.error('Invalid value type provided:', value);
      return;
    }

    const multiSelectParams = {
      prev: multiSelects,
      curr: value,
      options: selectOptions,
      defaultValues,
    };
    const convertedValues = convertMultiSelectValues(multiSelectParams);

    if (convertedValues.length === 0 || convertedValues.includes('ALL')) {
      updateForm(form => removeField(field, form));
    } else {
      updateForm(form => ({ ...form, [field]: convertedValues.join(', ') }));
    }

    setMultiSelects(convertedValues);
  };

  return {
    multiSelects,
    selectOptions,
    renderMultiSelects,
    changeMultiSelects,
  };
};

export default useSearchMultiSelect;

const getDisplayName = (options: readonly SearchOption[], value: string) => {
  const foundOption = options.find(option => option.value.toString() === value);
  return foundOption ? foundOption.displayName : '';
};

type ParamsForConvert = {
  prev: string[];
  curr: string[];
  options: readonly SearchOption[];
  defaultValues: string[];
};

const convertMultiSelectValues = ({ prev, curr, options }: ParamsForConvert) => {
  const allItemSelected = !prev.includes('ALL') && curr.includes('ALL');
  const allItemDeselected = prev.includes('ALL') && !curr.includes('ALL');
  const oneItemSelectedWithAll = prev.includes('ALL') && curr.includes('ALL');
  const noSelected = curr.length === 0;
  const everySelectedExceptAll =
    !curr.includes('ALL') &&
    curr.length === options?.filter(option => option.value !== 'ALL').length;

  if (allItemDeselected || noSelected) return [];
  if (allItemSelected || everySelectedExceptAll) return options?.map(option => option.value);
  if (oneItemSelectedWithAll) return curr.filter(value => value !== 'ALL');

  return curr;
};

const removeField = (field: string, form: SearchForm) => {
  const updatedForm = { ...form };
  delete updatedForm[field];
  return updatedForm;
};
