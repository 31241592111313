import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SearchForm, SearchOption } from '../../../types/form';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  field: string;
  selectOptions: readonly SearchOption[];
  defaultSelect?: string;
};

const useSearchSelect = (props: Props) => {
  const { updateForm, field, selectOptions, defaultSelect } = props;

  const [select, setSelect] = useState('');

  useEffect(() => {
    const initSelect = defaultSelect && defaultSelect !== 'ALL' ? { [field]: defaultSelect } : {};
    updateForm(form => ({ ...form, ...initSelect }));

    setSelect(defaultSelect || selectOptions[0].value);
  }, [field, defaultSelect, selectOptions[0].value]);

  const changeSelect = (value: string) => {
    updateForm(form => ({ ...form, [field]: value }));
    setSelect(value);
  };

  return {
    select,
    options: selectOptions,
    changeSelect,
  };
};

export default useSearchSelect;
