import { REACT_APP_STAGE } from 'consts/common/environment';
import devOnlyFlag from '../../../libs/common/devOnlyFlag';

export type GmsMenuItem = {
  title: string;
  subMenus?: GmsMenuItem[];
  route?: string;
  shouldShow?: boolean;
};

const shouldShow = true;
const shouldShowOnlyOnDev = REACT_APP_STAGE === 'dev';
const storeWarehouseIds = devOnlyFlag() ? [40, 42] : [44, 45]; // TODO: 창고타입으로 변경해야함
export const onSiteId = 46;

export const gmsMenus = (warehouseId: number | string): GmsMenuItem[] => {
  if (warehouseId.toString() === onSiteId.toString()) {
    return [
      {
        title: '매장',
        subMenus: [
          {
            title: '매장 본인수령',
            subMenus: [
              {
                title: '매장 본인수령 관리',
                route: '/gms/on-site/pickup',
                shouldShow,
              },
              {
                title: '본인수령 이력 조회',
                route: '/gms/on-site/pickup-history',
                shouldShow,
              },
            ],
            shouldShow,
          },
        ],
        shouldShow,
      },
      {
        title: '기본정보',
        subMenus: [
          {
            title: '작업기기',
            subMenus: [
              {
                title: '작업기기 관리',
                subMenus: [],
                route: '/gms/info/devices',
                shouldShow,
              },
            ],
            shouldShow,
          },
        ],
        shouldShow,
      },
    ];
  }

  return [
    {
      title: '입고',
      route: '',
      subMenus: [
        {
          title: '입고명세서',
          route: '',
          subMenus: [
            {
              title: '입고예정 관리',
              subMenus: [],
              route: '/gms/inbound/expect-receiving',
              shouldShow,
            },
            {
              title: '대체입고예정 관리',
              subMenus: [],
              route: '/gms/inbound/expect-inventory-transfer-receiving',
              shouldShow: shouldShowOnlyOnDev,
            },
            {
              title: '입고명세서 조회',
              subMenus: [],
              route: '/gms/inbound/registered-receiving',
              shouldShow,
            },
            {
              title: '입고검품 목록조회',
              subMenus: [],
              route: '/gms/inbound/inspection-receiving',
              shouldShow,
            },
          ],
          shouldShow,
        },
        {
          title: '거래명세서',
          route: '',
          subMenus: [
            {
              title: '입고확정 관리',
              subMenus: [],
              route: '/gms/inbound/receipts/confirms',
              shouldShow,
            },
            {
              title: '거래명세서 조회',
              subMenus: [],
              route: '/gms/inbound/specification-completed-receiving',
              shouldShow,
            },
            {
              title: '거래명세서 현황 조회',
              subMenus: [],
              route: '/gms/inbound/specification-completed-receiving-status',
              shouldShow,
            },
          ],
          shouldShow,
        },
        {
          title: '입고재고',
          route: '',
          subMenus: [
            {
              title: '진열대기 목록 조회',
              subMenus: [],
              route: '/gms/inbound/movement-receiving',
              shouldShow,
            },
            {
              title: '입고현황 조회',
              subMenus: [],
              route: '/gms/inbound/receivings',
              shouldShow,
            },
          ],
          shouldShow,
        },
        {
          title: '입고반품',
          route: '',
          subMenus: [
            {
              title: '입고반품 예정 관리',
              subMenus: [],
              route: '/gms/inbound/return/expect-receiving',
              shouldShow,
            },
            {
              title: '입고반품 검품 관리',
              subMenus: [],
              route: '/gms/inbound/return/inspection-receiving',
              shouldShow,
            },
            {
              title: '입고반품 확정 관리',
              subMenus: [],
              route: '/gms/inbound/return/confirm-receiving',
              shouldShow,
            },
          ],
          shouldShow: shouldShow,
        },
      ],
      shouldShow,
    },
    {
      title: '출고',
      subMenus: [
        {
          title: '출고지시서',
          subMenus: [
            {
              title: '출고지시서 관리',
              subMenus: [],
              route: '/gms/outbound/shipping-orders',
              shouldShow,
            },
            {
              title: '출고 현황 관리',
              subMenus: [],
              route: '/gms/outbound/shippings',
              shouldShow,
            },
            {
              title: '출고건별 포장경로 관리',
              subMenus: [],
              route: '/gms/outbound/shipping-conveyor-workflows',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '집품',
          subMenus: [
            {
              title: '집품그룹 생성',
              subMenus: [],
              route: '/gms/outbound/picking-group',
              shouldShow,
            },
            {
              title: '기증 집품그룹 생성',
              subMenus: [],
              route: '/gms/outbound/picking-group/donation',
              shouldShow,
            },
            {
              title: '대체출고 집품그룹 생성',
              subMenus: [],
              route: '/gms/outbound/picking-group/inventory-transfer',
              shouldShow: shouldShowOnlyOnDev,
            },
            {
              title: '집품그룹 관리',
              subMenus: [],
              route: '/gms/outbound/picking-groups',
              shouldShow,
            },
            {
              title: '집품 현황 조회',
              subMenus: [],
              route: '/gms/outbound/pickings',
              shouldShow,
            },
            {
              title: '아이템 집품 현황 조회',
              subMenus: [],
              route: '/gms/outbound/picking-items',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '검수',
          subMenus: [
            {
              title: '개별집품 검수 관리',
              subMenus: [],
              route: '/gms/outbound/inspection',
              shouldShow,
            },
            {
              title: '개별집품 검수현황 조회',
              subMenus: [],
              route: '/gms/outbound/inspections',
              shouldShow,
            },
            {
              title: '대량집품 검수현황 조회',
              subMenus: [],
              route: '/gms/outbound/bulk-inspections',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '포장',
          subMenus: [
            {
              title: '개별포장 관리',
              subMenus: [],
              route: '/gms/outbound/packing',
              shouldShow,
            },
            {
              title: '토트이관 관리',
              subMenus: [],
              route: '/gms/outbound/packing/change/tote',
              shouldShow,
            },
            {
              title: '대량포장 송장발행 관리',
              subMenus: [],
              route: '/gms/outbound/bulk-packing',
              shouldShow,
            },
            {
              title: '대량포장 집품그룹 관리',
              subMenus: [],
              route: '/gms/outbound/bulk-packings',
              shouldShow,
            },
            {
              title: '포장 현황 조회',
              subMenus: [],
              route: '/gms/outbound/packings',
              shouldShow,
            },
          ],
          route: '',
          shouldShow: !storeWarehouseIds.includes(Number(warehouseId)),
        },
        {
          title: '포장', // 매장 대체출고포장 관리메뉴는 매장 창고에서만 보여야 함
          subMenus: [
            {
              title: '매장 대체출고포장 관리',
              subMenus: [],
              route: '/gms/outbound/packing-in-store',
              shouldShow,
            },
            {
              title: '포장 현황 조회',
              subMenus: [],
              route: '/gms/outbound/packings',
              shouldShow,
            },
          ],
          route: '',
          shouldShow: storeWarehouseIds.includes(Number(warehouseId)),
        },
        {
          title: '작업기기',
          subMenus: [
            {
              title: '출고작업기기 현황 관리',
              subMenus: [],
              route: '/gms/outbound/devices-works',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
      ],
      shouldShow,
    },
    {
      title: '재고',
      subMenus: [
        {
          title: '재고',
          subMenus: [
            {
              title: '재고조회(아이템)',
              subMenus: [],
              route: '/gms/info/inventory-items/items',
              shouldShow,
            },
            {
              title: '재고조회(로케이션)',
              subMenus: [],
              route: '/gms/info/inventory-items/locations',
              shouldShow,
            },
            {
              title: '재고조정 이력 조회',
              subMenus: [],
              route: '/gms/info/inventories/adjusted-inventory-histories',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '재고이동',
          subMenus: [
            {
              title: '개별재고이동 관리',
              subMenus: [],
              route: '/gms/info/inventory-items/stock-movements',
              shouldShow,
            },
            {
              title: '재고이동 이력 조회',
              subMenus: [],
              route: '/gms/info/inventory-items/stock-movement-histories',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '재고조사',
          subMenus: [
            {
              title: '재고조사 관리',
              subMenus: [],
              route: '/gms/info/inventory-items/stock-takings',
              shouldShow,
            },
            {
              title: '재고조사 재고정보 조회',
              subMenus: [],
              route: '/gms/info/inventory-items/stock-takings/inventory-items/locations/items',
              shouldShow,
            },
            {
              title: '재고조사 진행현황 조회',
              subMenus: [],
              route: '/gms/info/inventory-items/stock-takings/progress',
              shouldShow,
            },
            {
              title: '재고조사 비교 조회',
              subMenus: [],
              route: '/gms/info/inventory-items/stock-takings/compare',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
      ],
      shouldShow,
    },
    {
      title: '외부입출고',
      subMenus: [
        {
          title: '대체출고',
          subMenus: [
            {
              title: '대체출고지시 생성',
              subMenus: [],
              route: '/gms/transfer/orders/generates',
              shouldShow,
            },
            {
              title: '대체출고지시서 관리',
              subMenus: [],
              route: '/gms/transfer/orders/searches',
              shouldShow,
            },
            {
              title: '출고이력 조회',
              subMenus: [],
              route: '',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
      ],
      shouldShow: shouldShowOnlyOnDev,
    },
    {
      title: '통계',
      subMenus: [
        {
          title: '출고',
          subMenus: [
            {
              title: '출고작업현황 조회',
              subMenus: [],
              route: '/gms/status/shipping-task',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
      ],
      shouldShow: shouldShowOnlyOnDev,
    },
    {
      title: '기본정보',
      subMenus: [
        {
          title: '작업기기',
          subMenus: [
            {
              title: '작업기기 관리',
              subMenus: [],
              route: '/gms/info/devices',
              shouldShow,
            },
            {
              title: '컨베이어 포장경로 관리',
              subMenus: [],
              route: '/gms/info/conveyors',
              shouldShow,
            },
            {
              title: '경로별 포장필요건 조회',
              subMenus: [],
              route: '/gms/info/conveyors/path',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '로케이션',
          subMenus: [
            {
              title: '회사 관리',
              subMenus: [],
              route: '/gms/info/locations/companies',
              shouldShow,
            },
            {
              title: '센터 관리',
              subMenus: [],
              route: '/gms/info/locations/centers',
              shouldShow,
            },
            {
              title: '창고 관리',
              subMenus: [],
              route: '/gms/info/locations/warehouses',
              shouldShow,
            },
            {
              title: '구역 관리',
              subMenus: [],
              route: '/gms/info/locations/areas',
              shouldShow,
            },
            {
              title: '통로 관리',
              subMenus: [],
              route: '/gms/info/locations/aisles',
              shouldShow,
            },
            {
              title: '구획 관리',
              subMenus: [],
              route: '/gms/info/locations/bays',
              shouldShow,
            },
            {
              title: 'BIN 관리',
              subMenus: [],
              route: '/gms/info/locations/bins',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '사용자',
          subMenus: [
            {
              title: '사용자 관리',
              subMenus: [],
              route: '/gms/info/users',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '아이템옵션',
          subMenus: [
            {
              title: '아이템옵션 관리',
              subMenus: [],
              route: '/gms/info/item-options',
              shouldShow,
            },
            {
              title: 'LPN 관리',
              subMenus: [],
              route: '/gms/info/item-options/lpns',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
        {
          title: '배송방식',
          subMenus: [
            {
              title: '배송방식 관리',
              subMenus: [],
              route: '/gms/info/carriers',
              shouldShow,
            },
          ],
          route: '',
          shouldShow,
        },
      ],
      shouldShow,
    },
  ];
};
