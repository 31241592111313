import { AxiosError, AxiosResponse } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { errorType } from '../../../../../types/common';
import { axiosV2 } from '../../../../../libs/common/fetcher';

export const updateShippingPickingWorkflow = async (requestBody: {
  shippingIds: number[];
  conveyorPath: string;
}) => {
  try {
    return await axiosV2.patch(`conveyors/shippings/multiple`, requestBody);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`포장경로정보 수정 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const updateConveyorPaths = async (
  requestBody: { shippingId: number; path: string }[]
): Promise<AxiosResponse | undefined> => {
  try {
    return await axiosV2.post('/conveyors/shippings/multiple', {
      payload: requestBody,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`excelUpload 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};

export const reassignAllConveyorPaths = async (warehouseId: number) => {
  try {
    return await axiosV2.patch(`conveyors/shippings/reassign-all?warehouseId=${warehouseId}`);
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`포장경로 자동일괄변경 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
