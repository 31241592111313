import GridSection from '../common/GridSection';
import Typography from '@mui/material/Typography';

type Props = {
  gridColumn: string;
  label: string;
};

const SearchLabel = (props: Props) => {
  const { gridColumn, label } = props;

  return (
    <GridSection sx={{ gridColumn }} isLabel>
      <Typography>{label}</Typography>
    </GridSection>
  );
};

export default SearchLabel;
