import { useSetRecoilState } from 'recoil';
import Button from '@mui/material/Button';
import { reloadOutletAtom } from '../../../../store/common/menu.recoil';

const InitScreenButton = () => {
  const setReloadOutlet = useSetRecoilState(reloadOutletAtom);

  const handleClick = () => setReloadOutlet(new Date().getMilliseconds());

  return (
    <Button variant="outlined" onClick={handleClick}>
      처음화면으로 이동
    </Button>
  );
};

export default InitScreenButton;
