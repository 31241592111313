import React from 'react';
import type { Dayjs } from 'dayjs';
import {
  LocalizationProvider,
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps,
} from '@mui/x-date-pickers-pro';
import type { DateRange } from '@mui/x-date-pickers-pro/internal/models/range';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker/DateRangePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { inputTheme } from '../../styles/customedMuiTheme';

const locale = 'ko';

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> & React.RefAttributes<HTMLInputElement>
) => JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = React.forwardRef(
  (props: SingleInputDateRangeFieldProps<Dayjs>, ref: React.Ref<HTMLInputElement>) => {
    return <SingleInputDateRangeField size="small" {...props} ref={ref} format={'YYYY-MM-DD'} />;
  }
) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

type Props = {
  value: DateRange<Dayjs>;
  onChange: (value: DateRange<Dayjs>) => void;
};

const SearchDateRangePicker = (props: Props) => {
  const { value, onChange } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DemoContainer components={['SingleInputDateRangeField']} sx={styles.demoContainer}>
        <DemoItem component="DateRangePicker">
          <DateRangePicker
            value={value}
            onChange={value => onChange(value)}
            slots={{ field: WrappedSingleInputDateRangeField }}
            slotProps={{
              textField: {
                placeholder: '시작일 - 종료일 선택',
                value: value,
                InputProps: { endAdornment: <CalendarMonthOutlinedIcon /> },
              },
              actionBar: { actions: ['cancel', 'accept'] },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default SearchDateRangePicker;

const styles = {
  demoContainer: {
    ...inputTheme.medium,
    p: '0px',
    '& .MuiInputBase-input': {
      pt: '0px',
      pb: '0px',
    },
  },
};
