export type Validator = {
  validate: (value: string) => boolean;
  message: string;
};

export const isCapital: Validator = {
  validate: (value: string) => value.toUpperCase() === value,
  message: 'A-Z 알파벳만 입력할 수 있습니다.',
};

export const isSingle: Validator = {
  validate: (value: string) => value.length === 1,
  message: '1글자만 입력할 수 있습니다.',
};

export const isRegistrationNumber: Validator = {
  validate: (value: string) => /^\d{3}-\d{2}-\d{4,5}$/.test(value),
  message: 'xxx-xx-xxxx(x) 형식입니다. ( -(하이픈)포함 )',
};

export const isPhoneNumber: Validator = {
  validate: (value: string) => /^\d{2,3}-\d{3,4}-\d{4}/.test(value),
  message: '(x)xx-(x)xxx-xxxx 형식입니다. ( -(하이픈)포함 )',
};

export const isNumber: Validator = {
  validate: (value: string) => !isNaN(Number(value)),
  message: '숫자만 입력할 수 있습니다.',
};

export const createMaxValidator = (max: number): Validator => ({
  validate: (value: string) => value.length <= max,
  message: `최대 ${max}글자만 입력할 수 있습니다.`,
});

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};
