const CONVEYOR_PATH_ENTRIES = [
  ['AIR_MANUAL', '1'],
  ['AIR_CARTON', '2'],
  ['AIR_GENESIS', '3'],
  ['CARTON', '4'],
  ['GENESIS', '5'],
  ['MANUAL', '6'],
] as const;

export const CONVEYOR_PATH = Object.fromEntries(CONVEYOR_PATH_ENTRIES) as {
  [K in (typeof CONVEYOR_PATH_ENTRIES)[number][0]]: (typeof CONVEYOR_PATH_ENTRIES)[number][1];
};

export const REVERSE_CONVEYOR_PATH = Object.fromEntries(
  CONVEYOR_PATH_ENTRIES.map(([key, value]) => [value, key])
) as {
  [K in (typeof CONVEYOR_PATH_ENTRIES)[number][1]]: (typeof CONVEYOR_PATH_ENTRIES)[number][0];
};

export const CONVEYOR_PATH_ARR = Object.entries(CONVEYOR_PATH).map(([key, value]) => ({
  value: key,
  displayName: value,
}));

export default CONVEYOR_PATH;
