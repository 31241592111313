import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import usePopup from '../../../../../hooks/usePopup';
import InfoListSection from '../../../../../components/common/typography/InfoListSection';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import { getJsonById } from '../services/getJsonById';

const modalWidth = 1000;

type Props = {
  id: string | undefined;
  selected: Record<'request' | 'response' | 'createdAt', string> | undefined;
  onClose: () => void;
};

type WaybillResults = {
  serialNumber: string;
  waybillCreatedAt: string;
  trackingNumber: string;
  carrierId: number;
};

const ErrorJsonModal = (props: Props) => {
  const { id, selected, onClose } = props;
  if (!selected) return null;

  const { request, response } = selected;

  const { showSnackbar } = usePopup();
  const [waybillResults, setWaybillResults] = useState<WaybillResults>();
  const carriers = useRecoilValue(carriersAtom);

  useEffect(() => {
    const fetchJson = async () => {
      if (!selected) return;

      const { serialNumber, trackingNumber, carrierId } = await getJsonById(Number(id));

      setWaybillResults({
        serialNumber,
        waybillCreatedAt: selected.createdAt,
        trackingNumber,
        carrierId,
      });
    };

    fetchJson();
  }, [id, selected]);

  const infoList = [
    { name: '출고ID: ', value: waybillResults?.serialNumber },
    { name: '송장발행일시: ', value: waybillResults?.waybillCreatedAt },
    { name: '배송방식: ', value: convertCarrierId(waybillResults?.carrierId, carriers)[0] },
    { name: '송장번호: ', value: waybillResults?.trackingNumber },
  ];

  return (
    <Stack width={modalWidth}>
      <InfoListSection infoList={infoList} hideValue={!request} />
      <Stack direction={'row'} justifyContent={'space-between'} gap={1} mt={2}>
        <Stack width={'49%'} flex={1}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
            <Typography fontWeight={700}>Request(요청) JSON</Typography>
            <Button
              variant={'outlined'}
              size={'small'}
              onClick={async () => {
                await navigator.clipboard.writeText(request);
                showSnackbar({
                  message: 'Request(요청) JSON이 복사되었습니다.',
                  severity: 'success',
                });
              }}
            >
              JSON 복사
            </Button>
          </Stack>
          <Box sx={styles.jsonText}>{request}</Box>
        </Stack>
        <Stack width={'49%'} flex={1}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
            <Typography fontWeight={700}>Response(응답) JSON</Typography>
            <Button
              variant={'outlined'}
              size={'small'}
              onClick={async () => {
                await navigator.clipboard.writeText(response);
                showSnackbar({
                  message: 'Response(응답) JSON이 복사되었습니다.',
                  severity: 'success',
                });
              }}
            >
              JSON 복사
            </Button>
          </Stack>
          <Box sx={styles.jsonText}>{response}</Box>
        </Stack>
      </Stack>
      <Box sx={styles.buttonContainer}>
        <Button onClick={onClose} variant="outlined">
          닫기
        </Button>
      </Box>
    </Stack>
  );
};

export default ErrorJsonModal;

const styles = {
  jsonText: {
    p: 2,
    height: '50vh',
    overflow: 'auto',
    whiteSpace: 'pre',
    border: '0.5px solid lightgrey',
    fontSize: 13,
    borderRadius: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },
};
