const shippingStatusCustomOrder = [
  'OPEN',
  'PREPARING_PICKING',
  'PICKING',
  'INSPECTION',
  'PACKING',
  'SHIPPED',
];

const pickingGroupCustomOrder = [
  'OPEN',
  'ASSIGNED_PDA',
  'PREPARING_PICKING',
  'PICKING',
  'COMPLETED',
];

const pickingGroupTypeCustomOrder = [
  'SINGLE',
  'BULK',
  'SINGLE_BULK',
  'DONATION',
  'INVENTORY_TRANSFER',
];

const workTypeCustomOrder = [
  'PICKING',
  'BULK_PICKING',
  'SINGLE_BULK_PICKING',
  'INSPECTION',
  'PACKING',
  'SINGLE_BULK_INSPECTION',
  'SINGLE_BULK_PACKING',
];

const adjustedInventoryHistoryType = [
  'VERSION',
  'DISCREPANCY',
  'AUTOGRAPH_RETURN',
  'PARTIAL_RETURN',
  'OTHER_ITEM_MOVEMENT',
  'MISS_SHIPMENT',
  'STORE_RETURN',
  'INTANGIBLE_ITEM_RECEIVING',
  'TANGIBLE_ITEM_RECEIVING',
  'DATA',
  'DISPOSAL',
  'DEFECT',
  'OTHERS',
];

const binTypeCustomOrder = [
  'STORE',
  'SUPPLEMENT',
  'PICKING',
  'EXCLUDE',
  'MOVE',
  'RCV',
  'LOST_OR_FAULTY',
  'LOST',
  'FAULTY',
  'INVENTORY_TRANSFER',
  'VARYTOTE',
];

const stocktakingTypeCustomOrder = ['REGULAR', 'MANUAL'];

const salasTypeByShopCustomOrder = [
  'OPERATING_CN',
  'OPERATING_COM',
  'OPERATING_JP',
  'SALES_COM',
  'SALES_JP',
  'OPERATING_KR',
  'SALES_KR',
  'SALES_CN',
];

const inventoryMovementHistoryTypeCustomOrder = [
  'RECEIVING',
  'MOVEMENT',
  'DATA_MOVEMENT',
  'RECEIVING_RETURN',
  'DEFECT_RETURN',
];

const receivingTypeCustomOrder = [
  'PURCHASE_ORDER',
  'RETURN',
  'DEFECT_RETURN',
  'EACH',
  'INVENTORY_TRANSFER',
];

export const CustomOrders: any = {
  shippingStatus: shippingStatusCustomOrder,
  pickingGroupStatus: pickingGroupCustomOrder,
  pickingGroupType: pickingGroupTypeCustomOrder,
  workType: workTypeCustomOrder,
  deviceType: 'desc',
  shippingOrderStatus: 'desc',
  shippingOrderType: 'desc',
  adjustedInventoryHistoryType: adjustedInventoryHistoryType,
  binType: binTypeCustomOrder,
  stocktakingType: stocktakingTypeCustomOrder,
  salesTypeByShop: salasTypeByShopCustomOrder,
  inventoryMovementHistoryType: inventoryMovementHistoryTypeCustomOrder,
  receivingType: receivingTypeCustomOrder,
};
