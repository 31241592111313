import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ktown4uLogoBase64 } from '../../../../assets/ktown4u_logo_base64';
import Barcode from '../../../../components/common/Barcode';
import { Pickup } from '../on-site/index.page';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 11,
          fontWeight: 600,
        },
      },
    },
  },
});

const Receipt = ({ pickup }: { pickup: Pickup }) => {
  const sortedItems = pickup.items?.sort((a, b) =>
    Number(a.goodsName) - Number(b.goodsName) ? 1 : -1
  );
  const totalQty = sortedItems?.reduce((curr, item) => curr + item.qty, 0);

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={styles.body}>
        <Stack sx={styles.top}>
          <Typography sx={{ fontSize: 24 }}>주문서</Typography>
          <Box sx={styles.zone}>
            <Typography sx={{ fontSize: 24, lineHeight: 1.6 }}>{pickup.zone}</Typography>
            <Typography sx={{ fontSize: 12, height: 23 }}>zone</Typography>
          </Box>
        </Stack>
        <Box sx={styles.userTable}>
          <Stack width="100%">
            <UserRow field="고객ID" value={maskId(pickup.userId)} />
            <UserRow field="고객명" value={maskName(pickup.userName)} />
            <UserRow field="연락처" value={maskPhone(pickup.userPhone)} />
            <UserRow field="수령신청일" value={pickup.requestedPickUpDate} />
            <UserRow field="주문일" value={pickup.orderDate} />
          </Stack>
        </Box>

        <Stack alignItems="center" mt={'24px'} mb={'12px'}>
          <Barcode value={pickup.orderSeq} height={60} width={1.2} />
          <Typography>{pickup.orderSeq}</Typography>
        </Stack>

        <Stack width="100%" my={1}>
          <Typography fontSize={14}>주문내역</Typography>
          <Divider sx={styles.outerDivider} />
          <Divider sx={styles.outerDivider} />
          <Stack flexDirection="row" width="100%" my={'4px'}>
            <Typography width="84%">상품명</Typography>
            <Typography width="16%" textAlign="end" mr="4px">
              수량
            </Typography>
          </Stack>
          <Box sx={styles.itemTable}>
            <Stack width="100%" gap="4px">
              {sortedItems.map(item => (
                <ItemRow
                  key={item.goodsId}
                  code={item.goodsCode}
                  name={item.goodsName}
                  qty={item.qty}
                />
              ))}
            </Stack>
          </Box>
          <Stack flexDirection="row" width="100%" my={'4px'}>
            <Typography width="84%">총수량</Typography>
            <Typography width="16%" textAlign="end" mr="4px">
              {totalQty}
            </Typography>
          </Stack>
          <Divider sx={styles.outerDivider} />
          <Divider sx={styles.outerDivider} />
        </Stack>
        <Box mt={1}>
          <img src={ktown4uLogoBase64} width={100} alt="ktown4u logo" />
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

export default Receipt;

const maskId = (id: string) => {
  if (!id) {
    return '';
  }

  if (id.length < 5) {
    return id[0] + new Array(id.length - 1).fill('*').join('');
  }

  const showingId = id.slice(0, 4);
  const indexOfAt = showingId.indexOf('@');

  if (indexOfAt > 0) {
    return id.slice(0, indexOfAt) + new Array(id.length - indexOfAt + 1).fill('*').join('');
  } else {
    return id.slice(0, 4) + new Array(id.length - 4).fill('*').join('');
  }
};

const maskName = (name: string) => {
  if (!name) {
    return '';
  }

  const englishRegex = /[a-zA-Z]/;
  if (englishRegex.test(name)) {
    if (name.length < 5) {
      return name.slice(0, name.length - 1) + '*';
    } else {
      return name.slice(0, 4) + new Array(name.length - 4).fill('*').join('');
    }
  }

  if (name.length < 3) {
    return name[0] + new Array(name.length - 1).fill('*').join('');
  } else {
    return name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
  }
};

const maskPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  if (phone.length < 8) {
    return phone[0] + new Array(phone.length - 1).fill('*').join('');
  }

  return (
    phone.slice(0, 3) +
    new Array(phone.length - 7).fill('*').join('') +
    phone.slice(phone.length - 4, phone.length)
  );
};

const UserRow = ({ field, value }: { field: string; value: string }) => {
  return (
    <Stack flexDirection="row" width="100%">
      <Typography sx={{ ml: '4px', width: '30%' }}>{field}</Typography>
      <Typography sx={{ mx: 1 }}>:</Typography>
      <Typography sx={{ width: '64%' }}>{value}</Typography>
    </Stack>
  );
};

const ItemRow = ({ code, name, qty }: { code: string; name: string; qty: number }) => {
  return (
    <Stack flexDirection="row" width="100%">
      <Typography sx={{ width: '84%', ml: '4px' }}>{`${code} ${name}`}</Typography>
      <Typography sx={{ width: '16%', textAlign: 'end', pr: '4px' }}>
        {qty.toLocaleString()}
      </Typography>
    </Stack>
  );
};

const styles = {
  body: {
    width: 256,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  top: {
    width: '100%',
    height: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  zone: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  userTable: {
    width: '100%',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    my: 1,
    py: '4px',
  },
  outerDivider: {
    width: '100%',
    borderTop: '1px soild black',
    my: '1px',
  },
  itemTable: {
    width: '100%',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    py: '4px',
  },
};
