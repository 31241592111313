import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { Dayjs } from 'dayjs';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { SearchForm, SearchOption } from '../../../types/form';
import { getDatesByRange } from '../../../libs/search/getDatesByRange';
import { dateRangeAmountOptions } from '../../../consts/common/dateRangeAmountOptions';
import { DateRangeGroup } from './SearchDateRangeBySelect';

const fromEdge = 'From';
const toEdge = 'To';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  typeOptions: readonly SearchOption[];
  rangeOptions?: readonly SearchOption[];
  defaultType?: string;
  defaultRange?: string;
};

const useSearchDateRangeBySelect = (props: Props) => {
  const {
    updateForm,
    typeOptions,
    rangeOptions = dateRangeAmountOptions,
    defaultType,
    defaultRange,
  } = props;

  const [dateRange, setDateRange] = useState<DateRangeGroup>({
    type: '',
    dates: [null, null],
    range: '',
  });

  useEffect(() => {
    const initType = defaultType || typeOptions[0].value;
    const initRange = defaultRange || 'all';

    setDateRange(initDateRange(initType, initRange));
    updateForm(form => ({ ...form, ...makeDates(initType, getDatesByRange(initRange)) }));
  }, [defaultRange, defaultType, typeOptions[0].value]);

  const changeType = (dateType: string) => {
    updateForm(form => ({
      ...deletePrevDates(form, dateRange.type),
      ...makeDates(dateType, dateRange.dates),
    }));
    setDateRange(dateRange => ({ ...dateRange, type: dateType }));
  };

  const changeDates = (value: DateRange<Dayjs>) => {
    updateForm(form => ({ ...form, ...makeDates(dateRange.type, value) }));
    setDateRange(dateRange => ({ ...dateRange, dates: value }));
  };

  const changeRange = (value: string) => {
    updateForm(form => ({ ...form, ...makeDates(dateRange.type, getDatesByRange(value)) }));
    setDateRange(dateRange => ({
      ...dateRange,
      dates: getDatesByRange(value),
      range: value,
    }));
  };

  return {
    dateRange,
    typeOptions,
    rangeOptions,
    changeType,
    changeDates,
    changeRange,
  };
};

export default useSearchDateRangeBySelect;

const makeDates = (dateRangeType: string, dateRange: DateRange<Dayjs>) => {
  const [startDate, endDate] = dateRange;

  const dates: SearchForm = {};

  if (!startDate || !endDate) return dates;

  dates[`${dateRangeType + fromEdge}`] = startDate?.format('YYYY-MM-DD') ?? null;
  dates[`${dateRangeType + toEdge}`] = endDate?.format('YYYY-MM-DD') ?? null;

  return dates;
};

const initDateRange = (dateRangeType: string, dateRangeOption: string) => ({
  type: dateRangeType,
  dates: getDatesByRange(dateRangeOption),
  range: dateRangeOption,
});

const deletePrevDates = (form: SearchForm, dateRangeType: string) => {
  const newForm = { ...form };
  delete newForm[`${dateRangeType + fromEdge}`];
  delete newForm[`${dateRangeType + toEdge}`];

  return newForm;
};
