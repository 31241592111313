import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  afterProgressTitle,
  beforeProgressTitle,
  inProgressTitle,
} from '../../../../../styles/timelineItemTitle';
import usePopup from '../../../../../hooks/usePopup';
import { errorType } from '../../../../../types/common';
import TimelineItem from '../../../outbound/components/TimelineItem';
import Receipt from '../../components/Receipt';
import { Pickup } from '../index.page';
import { getPickupToPrint, patchCompletePickup } from '../services';
import { sortAndMapItems } from './BarcodeScanLine';

export const PICKUP_STEP = 1;

type Props = {
  orderSeq: string | undefined;
  changeStep: (step: number) => void;
};

const CompletePickup = (props: Props) => {
  const { orderSeq, changeStep } = props;
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef, onAfterPrint: () => window.close() });
  const [pickupForReceipt, setPickupForReceipt] = useState<Pickup>();
  const { showAlert } = usePopup();

  useEffect(() => {
    const fetchPickup = async () => {
      if (!orderSeq) return;

      const response = await getPickupToPrint(orderSeq);
      if (response?.status === 200) {
        const pickupWithSortedItems = {
          ...response.data,
          items: sortAndMapItems(response.data.items),
        };

        setPickupForReceipt(pickupWithSortedItems);
      } else {
        const error = response?.data as errorType;
        showAlert({ message: error?.errorMessage });
      }
    };

    fetchPickup();
  }, [orderSeq]);

  const handlePrintOrder = () => {
    reactToPrintFn();
  };

  const handleCompletePickup = async () => {
    if (!orderSeq) return;

    const response = await patchCompletePickup(orderSeq);
    if (response?.status === 200) {
      changeStep(2);
    } else {
      const error = response?.data as errorType;
      showAlert({ message: error?.errorMessage });
    }
  };

  return (
    <TimelineItem
      isLast
      step={PICKUP_STEP}
      beforeProgressComponent={
        <Typography sx={beforeProgressTitle}>
          {`전달이 완료되었다면 '전달완료'를 클릭해 주세요.`}
        </Typography>
      }
      inProgressComponent={
        <>
          <Typography sx={inProgressTitle}>
            {`전달이 완료되었다면 '전달완료'를 클릭해 주세요.`}
          </Typography>
          <Stack direction="row" justifyContent="center" m={1} gap={2}>
            <Button sx={styles.button} variant="outlined" onClick={() => changeStep(0)}>
              취소
            </Button>
            <Button sx={styles.button} variant="outlined" onClick={handlePrintOrder}>
              주문서 출력
            </Button>
            <Button sx={styles.button} variant="contained" onClick={handleCompletePickup}>
              전달완료
            </Button>
          </Stack>
          <Box sx={styles.container} ref={contentRef}>
            {pickupForReceipt && <Receipt pickup={pickupForReceipt} />}
          </Box>
        </>
      }
      afterProgressComponent={
        <Typography sx={afterProgressTitle}>
          {`전달이 완료되었다면 '전달완료'를 클릭해 주세요.`}
        </Typography>
      }
    />
  );
};

export default CompletePickup;

const styles = {
  container: {
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
    backgroundColor: 'white',
  },
  button: { height: 60, width: 148, fontSize: '20px' },
};
