import pLimit from 'p-limit';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Page from 'components/common/Layout/Page';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import type { ShippingInShippingOrder } from 'types/outbound';
import { carrierOptionsAtom } from '../../../../store/outbound/carrier.recoil';
import gridColDef from './gridColDef';
import usePopup from 'hooks/usePopup';
import FormModal from 'components/common/FormModal';
import { CreateWaybillRequest, createWaybill, getShippingIdWithoutWaybill } from './services';
import YtoFetchResultModal from './components/YtoFetchResultModal';
import LoadingModal from '../components/LoadingModal';
import TopBoard from '../../components/TopBoard';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import { refCodeOptionsAtom } from '../../../../store/outbound/refCode.recoil';
import useConditionalSWR from '../../components/useConditionalSwr';
import LoadingButton from '@mui/lab/LoadingButton';
import { generateExcelDownload } from './services/excelDownload';
import SearchContainer from '../../../../components/Search/SearchContainer';
import SearchButtonGroup from '../../../../components/Search/SearchButtonGroup';
import useShippingSearch from './hooks/useShippingSearch';
import { SearchSelect } from '../../../../components/Search/SearchSelect';
import { SearchMultiSelect } from '../../../../components/Search/SearchMultiSelect';
import { SearchTextWithSelect } from '../../../../components/Search/SearchTextWithSelect';
import { SearchDateRangeBySelect } from '../../../../components/Search/SearchDateRangeBySelect';
import { convertWarehouseToSearchOptions } from '../../../../libs/warehouse/convertWarehouseToSearchOptions';
import { defaultOption } from '../../../../consts/common/defaultOption';
import { reloadOutletAtom } from '../../../../store/common/menu.recoil';
import SearchLabel from '../../../../components/Search/SearchLabel';

const searchTextOptions = [
  {
    displayName: '출고지시ID',
    value: 'shippingOrderSerialNumber',
  },
  {
    displayName: '집품그룹ID',
    value: 'pickingGroupSerialNumber',
  },
] as const;

const shippingOptions = [
  {
    displayName: '출고ID',
    value: 'shippingSerialNumber',
  },
  {
    displayName: '배송ID',
    value: 'deliverySeq',
  },
  {
    displayName: '배송번호',
    value: 'deliveryId',
  },
  {
    displayName: '송장번호',
    value: 'trackingNumber',
  },
] as const;

const dateRangeTypeOptions = [
  {
    displayName: '출고지시일',
    value: 'shippingOrderCreatedAt',
  },
  {
    displayName: '출고기준일',
    value: 'shippingBasedAt',
  },
  {
    displayName: '출고완료일',
    value: 'shippingCompletedAt',
  },
] as const;

const isInspectionSkippedOptions = [
  { displayName: 'Y', value: 'false' },
  { displayName: 'N', value: 'true' },
] as const;

type WaybillResult = {
  isSuccess: boolean;
  errorMessage: string;
};

type Shippings = ShippingInShippingOrder & {
  shippingSerialNumber: string;
  isStock: boolean;
  packingErrorCode: string;
  trackingNumber: string;
};

type ShippingResponse = {
  content: Shippings[];
  totalElements: number;
  size: number;
  number: number;
};

export type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: string;
};

type ShippingStatusCount = {
  status: string;
  count: number;
};

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

type YtoFetchResult = {
  status: number;
  data: {
    total: number;
    successCount: number;
    failCount: number;
    errorMessage?: string;
  };
};

const defaultYtoFetchResult = {
  status: 0,
  data: {
    total: 0,
    successCount: 0,
    failCount: 0,
  },
};

const Shippings = () => {
  const [ytoFetchResult, setYtoFetchResult] = useState<YtoFetchResult>(defaultYtoFetchResult);
  const [isFetchingYtoWaybills, setIsFetchingYtoWaybills] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const { showDialog, showAlert, showSnackbar, showErrorDialog } = usePopup();

  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const carrierOptions = useRecoilValue(carrierOptionsAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const salesTypeByShopOptions = refCodeOptions?.salesTypeByShop || [];
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const trackingStatusOptions = refCodeOptions?.trackingStatus || [];
  const setReloadOutlet = useSetRecoilState(reloadOutletAtom);

  const { updateForm, handleSearch } = useShippingSearch();

  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: globalWarehouse,
  });

  const { data: shippingStatusCounts, mutate: countMutate } = useConditionalSWR<
    ShippingStatusCount[]
  >([`/shippings/status/counts`, { warehouseId: globalWarehouse }], fetcher, hasSearched);

  const { data, isValidating, mutate } = useConditionalSWR<ShippingResponse>(
    [`/shippings`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const topBoard = [
    {
      title: '송장(선)미발행',
      value: 0,
    },
    {
      title: '송장오류',
      value: 0,
    },
    {
      title: 'DHL POP 미발행',
      value: 0,
    },
    {
      title: 'DHL POP 오류',
      value: 0,
    },
  ];

  shippingStatusCounts?.forEach((item: ShippingStatusCount) => {
    if (item.status === 'unissued') {
      topBoard[0].value = item.count;
    } else if (item.status === 'error') {
      topBoard[1].value = item.count;
    }
  });

  const makeRowIndex = (data: ShippingResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: Shippings, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleInitClick = async () => {
    setReloadOutlet(new Date().getMilliseconds());
  };

  const pageMutate = async () => {
    await mutate();
    await countMutate();
  };

  const handleSearchClick = async () => {
    const updatedForm = handleSearch();

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await pageMutate();
  };

  const handleFetchYtoWaybills = () => {
    showDialog({
      message: 'YTO 송장을 발행하시겠습니까?',
      buttons: [
        {
          text: '취소',
        },
        {
          text: '확인',
          onClick: async () => {
            setIsFetchingYtoWaybills(true);
            // 송장발행할 출고건 조회
            const shippingIdsResponse = await getShippingIdWithoutWaybill(globalWarehouse);
            const shippingIds = shippingIdsResponse?.data.shippingIds;
            const shippingIdsWithWaybill = shippingIds?.length > 0;
            if (!shippingIdsWithWaybill) {
              showAlert({ message: 'YTO송장을 발행할 출고건이 없습니다' });
              return;
            }

            // 송장발행: 10개씩 동시에 요청
            const concurrency = 20;
            const limit = pLimit(concurrency);
            const createdWaybills: WaybillResult[] = shippingIds?.map((shippingId: number) =>
              limit(() => onCreateWaybill(shippingId))
            );

            // 결과 출력
            const result = await Promise.all(createdWaybills);
            printResult(result);

            setIsFetchingYtoWaybills(false);
            await pageMutate();
          },
        },
      ],
    });
  };

  const onCreateWaybill = async (shippingId: number) => {
    const requestBody: CreateWaybillRequest = {
      warehouseId: globalWarehouse,
      shippingIds: [shippingId],
    };
    type CreateWaybillResponse = {
      isSuccess: boolean;
      deliveryId: number;
      errorMessage: string;
    };
    const response = await createWaybill(requestBody);
    if (response?.status !== 200) {
      return { isSuccess: false, errorMessage: '송장 발행 요청이 실패했습니다.' };
    }
    const createdWaybillResponses = response?.data as CreateWaybillResponse[];
    const result: WaybillResult[] = createdWaybillResponses.map(item => {
      if (item.isSuccess ?? false) {
        return { isSuccess: true, errorMessage: '' };
      } else {
        const errorMessage = `배송번호: ${item.deliveryId} / 실패사유: ${item?.errorMessage}`;
        return { isSuccess: false, errorMessage: errorMessage };
      }
    });
    return result[0];
  };

  const printResult = (result: WaybillResult[]) => {
    const totalCount = result.length;
    const successCount = result.filter(item => item.isSuccess).length;
    const failErrorMessages = result.filter(item => !item.isSuccess).map(item => item.errorMessage);

    console.log(failErrorMessages);

    setYtoFetchResult({
      status: 200,
      data: {
        total: totalCount,
        successCount: successCount,
        failCount: totalCount - successCount,
      },
    });
  };

  const handleClose = () => {
    setYtoFetchResult(defaultYtoFetchResult);
  };

  const handleShippingExcelDownload = async () => {
    setIsDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
      setIsDownload(false);
    } else {
      setIsDownload(false);
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        출고 현황 관리
      </Typography>
      <TopBoard data={topBoard} />
      <SearchContainer gridTemplateColumns={gridTemplateColumns}>
        <SearchLabel label={'창고'} gridColumn={'1/2'} />
        <SearchSelect
          field="warehouseId"
          updateForm={updateForm}
          selectOptions={[defaultOption, ...convertWarehouseToSearchOptions(warehouseOption)]}
          gridColumn={'2/4'}
          defaultSelect={globalWarehouse}
        />
        <SearchLabel label={'출고상태'} gridColumn={'4/5'} />
        <SearchSelect
          field="status"
          updateForm={updateForm}
          selectOptions={[defaultOption, ...shippingStatusOptions]}
          gridColumn={'5/6'}
        />
        <SearchLabel label={'검수필요여부'} gridColumn={'6/7'} />
        <SearchSelect
          field="isInspectionSkipped"
          updateForm={updateForm}
          selectOptions={[defaultOption, ...isInspectionSkippedOptions]}
          gridColumn={'7/10'}
        />
        <SearchLabel label={'매출구분'} gridColumn={'10/11'} />
        <SearchMultiSelect
          updateForm={updateForm}
          field={'salesTypeByShops'}
          selectOptions={[defaultOption, ...salesTypeByShopOptions]}
          gridColumn={'11/13'}
        />
        <SearchLabel label={'기간'} gridColumn={'1/2'} />
        <SearchDateRangeBySelect
          updateForm={updateForm}
          typeOptions={dateRangeTypeOptions}
          gridColumns={{ type: '2/4', dates: '4/6', range: '6/7' }}
          defaultRange={'lastMonth'}
        />
        <SearchLabel label={'배송방식'} gridColumn={'7/8'} />
        <SearchMultiSelect
          updateForm={updateForm}
          field={'carrierIds'}
          selectOptions={[defaultOption, ...carrierOptions]}
          gridColumn={'8/10'}
        />
        <SearchLabel label={'송장여부'} gridColumn={'10/11'} />
        <SearchSelect
          updateForm={updateForm}
          field="trackingStatus"
          selectOptions={[defaultOption, ...trackingStatusOptions]}
          gridColumn={'11/13'}
        />
        <SearchLabel label={'검색어'} gridColumn={'1/2'} />
        <SearchTextWithSelect
          updateForm={updateForm}
          selectOptions={searchTextOptions}
          gridColumns={{ select: '2/4', text: '4/7' }}
        />
        <SearchLabel label={'출고건'} gridColumn={'7/8'} />
        <SearchTextWithSelect
          updateForm={updateForm}
          selectOptions={shippingOptions}
          gridColumns={{ select: '8/10', text: '10/13' }}
        />
        <SearchButtonGroup
          gridRow={4}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
        />
      </SearchContainer>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            출고 목록
          </Typography>
          <Box>
            <LoadingButton
              sx={styles.gridBorderTopButton}
              variant="outlined"
              suppressHydrationWarning
              loading={isDownload}
              onClick={handleShippingExcelDownload}
            >
              {`엑셀다운로드`}
            </LoadingButton>
            <Button
              sx={listBoardTheme.headerButton}
              variant="contained"
              suppressHydrationWarning
              onClick={handleFetchYtoWaybills}
            >
              YTO송장 자동발행
            </Button>
          </Box>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
      <FormModal
        open={ytoFetchResult.data.total > 0}
        title="YTO 송장발행 완료"
        onClose={handleClose}
      >
        <YtoFetchResultModal
          total={ytoFetchResult.data.total}
          success={ytoFetchResult.data.successCount}
          error={ytoFetchResult.data.failCount}
          onClose={handleClose}
        />
      </FormModal>
      {isFetchingYtoWaybills && (
        <LoadingModal isLoading={isFetchingYtoWaybills} message={'YTO송장 자동발행중...'} />
      )}
    </Page>
  );
};

export default Shippings;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  gridBorderTopButton: {
    marginRight: 1,
  },
};
