import { Dispatch, Fragment, SetStateAction } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import { SearchForm, SearchOption } from '../../../types/form';
import { inputTheme, listItemTextTheme, selectTheme } from '../../../styles/customedMuiTheme';
import GridSection from '../../common/GridSection';
import useSearchTextWithSelect from './useSearchTextWithSelect';

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  selectOptions: readonly SearchOption[];
  defaultSelect?: string;
  defaultText?: string;
  gridColumns: {
    select: `${string}/${string}`;
    text: `${string}/${string}`;
  };
  gridRow?: number;
};

const SearchTextWithSelect = (props: Props) => {
  const { select, options, changeSelect, text, changeText } = useSearchTextWithSelect(props);

  return (
    <Fragment>
      <GridSection sx={{ gridColumn: props.gridColumns.select, gridRow: props.gridRow }}>
        <Select
          value={select}
          onChange={e => changeSelect(e.target.value)}
          sx={selectTheme.medium}
          fullWidth
        >
          {options?.map(option => (
            <MenuItem key={option.displayName} value={option.value.toString()}>
              <ListItemText sx={listItemTextTheme.medium} primary={option.displayName} />
            </MenuItem>
          ))}
        </Select>
      </GridSection>
      <GridSection sx={{ gridColumn: props.gridColumns.text, gridRow: props.gridRow }}>
        <TextField
          variant="outlined"
          size="small"
          value={text}
          onChange={e => changeText(e.target.value)}
          fullWidth
          sx={inputTheme.medium}
        />
      </GridSection>
    </Fragment>
  );
};

export default SearchTextWithSelect;
