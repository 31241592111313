import type { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

enum RANGE {
  today = 'today',
  yesterday = 'yesterday',
  lastThreeDays = 'lastThreeDays',
  lastSevenDays = 'lastSevenDays',
  lastMonth = 'lastMonth',
  all = 'all',
}

export const getDatesByRange = (range: string): DateRange<Dayjs> => {
  let endDate: Dayjs | null = dayjs();
  let startDate = null;

  if (range === RANGE.today) {
    startDate = endDate.clone();
  } else if (range === RANGE.yesterday) {
    startDate = endDate.subtract(1, 'day');
    endDate = startDate.clone();
  } else if (range === RANGE.lastThreeDays) {
    startDate = endDate.subtract(3, 'day');
  } else if (range === RANGE.lastSevenDays) {
    startDate = endDate.subtract(7, 'day');
  } else if (range === RANGE.lastMonth) {
    startDate = endDate.subtract(30, 'day');
  } else if (range === RANGE.all) {
    // 전체기간을 검색하면, 서버에 과부하가 발생함.
    // 오늘 기준 90일 전부터 90일 후까지 검색하도록 설정함.
    startDate = endDate.subtract(90, 'day');
    endDate = endDate.add(90, 'day');
  } else {
    throw new Error('Invalid date range option');
  }

  return [startDate, endDate];
};
