import { Dispatch, Fragment, SetStateAction } from 'react';
import { Dayjs } from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { SearchForm, SearchOption } from '../../../types/form';
import { listItemTextTheme, selectTheme } from '../../../styles/customedMuiTheme';
import GridSection from '../../common/GridSection';
import SearchDateRangePicker from '../SearchDateRangePicker';
import { RANGE } from '../../common/Filter/DateRangePickerWithSelect/RangeSelect';
import useSearchDateRangeBySelect from './useSearchDateRangeBySelect';

export type DateRangeGroup = {
  type: string;
  dates: DateRange<Dayjs>;
  range: string;
};

type Props = {
  updateForm: Dispatch<SetStateAction<SearchForm>>;
  typeOptions: readonly SearchOption[];
  rangeOptions?: readonly SearchOption[];
  defaultType?: string;
  defaultRange?: keyof typeof RANGE;
  gridColumns: {
    type: `${string}/${string}`;
    dates: `${string}/${string}`;
    range: `${string}/${string}`;
  };
  gridRow?: number;
};

const SearchDateRangeBySelect = (props: Props) => {
  const { dateRange, typeOptions, rangeOptions, changeType, changeDates, changeRange } =
    useSearchDateRangeBySelect(props);

  return (
    <Fragment>
      <GridSection sx={{ gridColumn: props.gridColumns.type, gridRow: props.gridRow }}>
        <Select
          value={dateRange.type}
          onChange={e => changeType(e.target.value)}
          sx={selectTheme.medium}
          fullWidth
        >
          {typeOptions?.map(option => (
            <MenuItem key={option.displayName} value={option.value.toString()}>
              <ListItemText sx={listItemTextTheme.medium} primary={option.displayName} />
            </MenuItem>
          ))}
        </Select>
      </GridSection>
      <GridSection sx={{ gridColumn: props.gridColumns.dates, gridRow: props.gridRow }}>
        <SearchDateRangePicker value={dateRange.dates} onChange={changeDates} />
      </GridSection>
      <GridSection sx={{ gridColumn: props.gridColumns.range, gridRow: props.gridRow }}>
        <Select
          value={dateRange.range}
          onChange={e => changeRange(e.target.value)}
          sx={selectTheme.medium}
          fullWidth
        >
          {rangeOptions?.map(option => (
            <MenuItem key={option.displayName} value={option.value.toString()}>
              <ListItemText sx={listItemTextTheme.medium} primary={option.displayName} />
            </MenuItem>
          ))}
        </Select>
      </GridSection>
    </Fragment>
  );
};

export default SearchDateRangeBySelect;
