import { datadogRum } from '@datadog/browser-rum';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';
import { INSPECTION } from 'consts/outbound/messages';
import fetcher, { axiosV2 } from 'libs/common/fetcher';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { inspectionAtom, prevInspectionAtom } from 'store/outbound/inspection.recoil';

import { isFetchingPackingAtom, stepAtom } from 'store/outbound/packing.recoil';
import { afterProgressTitle, inProgressTitle } from 'styles/timelineItemTitle';
import usePopup from '../../../../../hooks/usePopup';
import { convertCarrierId } from '../../../../../libs/common/convertCarrier';
import { barcodeAtom } from '../../../../../store/common/barcode.recoil';
import { printerAtom } from '../../../../../store/common/printer.recoil';
import { carriersAtom } from '../../../../../store/outbound/carrier.recoil';
import Table, { TableCell, TableRow } from '../../../components/Table';

import BarcodeInput from '../../components/BarcodeInput';
import TimelineItem from '../../components/TimelineItem';
import useBarcode from '../../hooks/useBarcode';
import { createRefundErrorInspectionHistory, refundErrorHistoryParams } from '../services';
import getInspectionImage from './getInspectionImage';

const firstStep = 0;

const ToteCodeLine = () => {
  const [inspection, setInspection] = useRecoilState(inspectionAtom);
  const [prevInspection, setPrevInspection] = useRecoilState(prevInspectionAtom);
  const [selectedStep, setSelectedStep] = useRecoilState(stepAtom);
  const [isLoading, setIsLoading] = useRecoilState(isFetchingPackingAtom);
  const { barcode, setBarcode, isBarcodeValid, handleError } = useBarcode();
  const carriers = useRecoilValue(carriersAtom);
  const resetStep = useResetRecoilState(stepAtom);
  const resetBarcode = useResetRecoilState(barcodeAtom);
  const { showErrorDialog, showSnackbar } = usePopup();
  const printer = useRecoilValue(printerAtom);

  useEffect(() => {
    if (selectedStep === firstStep) {
      setBarcode('');
    }
  }, [selectedStep, setBarcode]);

  const handleScanBarcode = async () => {
    if (!isBarcodeValid()) {
      return;
    }

    datadogRum.addAction(`Scanned Tote Barcode: ${barcode}`, { barcode });
    await handleFetchInspectionInfo();
  };

  const requestRefundError = async (inspection: any) => {
    const requestBody: refundErrorHistoryParams = {
      inspectionId: inspection.inspectionId,
      historyType: 'REFUND',
      historyDescription: '환불이력이 존재합니다.',
    };
    return await createRefundErrorInspectionHistory(requestBody);
  };

  const handleFetchInspectionInfo = async () => {
    setIsLoading(true);
    try {
      const inspection = await fetcher(`/inspections/${barcode}`);
      if (inspection.hasRefundHistory) {
        // 환불이력 존재 오류 등록
        const response = await requestRefundError(inspection);
        if (response?.status === 200) {
          showErrorDialog({
            title: '환불이력 출고건',
            errorMessage:
              '환불이력 출고건으로 ‘검수중지‘ 처리되었습니다.\n담당 매니저를 호출해 주세요.',
            buttons: [{ text: '닫기' }],
          });
          // 환불이력 이미지 출력
          const response = await getInspectionImage(inspection?.inspectionId);
          if (response?.status === 200) {
            printImage(response.data?.base64Image);
          } else {
            showErrorDialog({
              title: '이미지 요청 실패',
              errorMessage: response?.data?.errorMessage,
              buttons: [{ text: '확인' }],
            });
          }
        }
        resetStep();
        resetBarcode();
      } else {
        await axiosV2.post(`/inspections/${barcode}`);
        setInspection(inspection);
        setPrevInspection(inspection);
        setSelectedStep(step => step + 1);
      }
    } catch (error) {
      handleError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const printImage = async (image: string) => {
    printer.send(
      `${image}`,
      () => {
        showSnackbar({ message: '환불이력 출력이 완료되었습니다.', severity: 'success' });
      },
      (error: string) => {
        showErrorDialog({
          title: '환불이력 출력 실패',
          top: '환불이력 출력을 실패하였습니다',
          errorMessage: error,
          buttons: [{ text: '확인' }],
        });
      }
    );
  };

  const makeLabelSx = (length: number) => {
    if (length < 3) {
      return { height: '56px', fontSize: '16px' };
    }

    return { height: `${56 + length * (8 + length)}px`, fontSize: '16px' };
  };

  const makeTableRowHeight = (length: number) => {
    if (length < 3) {
      return '56px';
    }
    return `${56 + length * (8 + length)}px`;
  };

  return (
    <>
      <TimelineItem
        step={0}
        inProgressComponent={
          <>
            <Typography sx={inProgressTitle}>{INSPECTION.scanTote}</Typography>
            <BarcodeInput
              value={barcode}
              onSubmit={handleScanBarcode}
              onChange={e => setBarcode(e.target.value)}
              label="바코드"
              isLoading={isLoading}
            />
          </>
        }
        afterProgressComponent={
          <>
            <Box sx={styles.titleContainer}>
              <Typography sx={afterProgressTitle}>{INSPECTION.scanTote}</Typography>
            </Box>
            <Table>
              <TableRow height={makeTableRowHeight(inspection?.toteBarcodes?.length)}>
                <TableCell
                  label="토트"
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  value={inspection?.toteBarcodes.map((barcode, index) => (
                    <Box key={index} sx={styles.barcode}>
                      {barcode}
                    </Box>
                  ))}
                />
                <TableCell
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  label="출고ID"
                  value={inspection?.shippingSerialNumber}
                />
              </TableRow>
              <TableRow height={makeTableRowHeight(inspection?.toteBarcodes?.length)}>
                <TableCell
                  label="배송방식"
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  value={convertCarrierId(inspection?.carrierId, carriers)}
                />
                <TableCell
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  label="최대무게제한(g)"
                  value={inspection?.maxWeight?.toLocaleString()}
                />
              </TableRow>
              <TableRow height={makeTableRowHeight(inspection?.toteBarcodes?.length)}>
                <TableCell
                  label="작업지시서"
                  labelSx={makeLabelSx(inspection?.toteBarcodes?.length)}
                  value={inspection?.adminComment}
                />
              </TableRow>
            </Table>
          </>
        }
      />
    </>
  );
};

export default ToteCodeLine;

const styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  button: { height: '40px', fontSize: '17px' },
  barcode: { fontSize: '16px' },
  abcd: { height: '200px' },
};
