import { atom } from 'recoil';
import { Carrier } from '../../types/outbound';

export type BulkPacking = {
  bulkPackingId: number;
  sameShippingCount: number;
  pickingGroupSerialNumber: string;
  estimatedWeight: number;
  totalWeightOfShipping?: number;
  pickingGroupType?: string;
  totes: {
    toteBarcode: string;
    totalSkuCount: number;
    totalQuantity: number;
  }[];
  shippingItems: {
    itemId: number;
    barcode: string;
    code: string;
    itemName: string;
    imageUrl: string;
    option?: string;
    orderQuantity: number;
  }[];
  carriers: Carrier[];
};

type Mm = number;
export type Box = {
  id: number | string;
  name: string;
  barcode: string;
  width: Mm;
  length: Mm;
  height: Mm;
};

export const bulkPackingDisplayWeightAtom = atom({
  key: 'bulkPackingDisplayWeightAtom',
  default: '',
});

export const bulkPackingIsLoadingAtom = atom<boolean>({
  key: 'bulkPackingIsLoadingAtom',
  default: false,
});

export const bulkPackingAtom = atom<BulkPacking | undefined>({
  key: 'bulkPackingAtom',
  default: undefined,
});

export const bulkPackingBoxAtom = atom<Box | undefined>({
  key: 'bulkPackingBoxAtom',
  default: undefined,
});

export const bulkPackingStepAtom = atom<number>({
  key: 'bulkPackingStepAtom',
  default: 0,
});

export default bulkPackingAtom;
