import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useSWR from 'swr';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import fetcher from '../../../../../../libs/common/fetcher';
import { COLORS } from '../../../../../../consts/common/colors';
import type { Packing } from '../../../../../../types/outbound';
import PopupTemplate from '../../../components/PopupTemplate';
import ListTable, { Row, Value } from '../../../components/ListTable';
import FormModal from '../../../../../../components/common/FormModal';
import ErrorJsonModal from '../../components/ErrorJsonModal';
import { isValidJSON } from '../../../../../../libs/common/validators';

type History = {
  errorCode: string;
  errorMessage: string;
  createdAt: string;
  request: string;
  response: string;
};

const PackingErrors = () => {
  const { id } = useParams();
  const [selectedError, setSelectedError] = useState<Record<string, string>>();

  const { data, error } = useSWR<History[]>(`/packings/${id}/histories`, fetcher);
  const { data: packing } = useSWR<Packing>(`/packings/${id}`, fetcher);

  const histories = data
    ?.map((history, i) => ({
      ...history,
      order: i + 1,
      id: i.toString(),
      createdAt: new Date(history.createdAt).toLocaleString('sv-SE'),
      hasJson: !!history.request,
    }))
    .sort((a, b) => b.order - a.order);

  const handleClickCell = (row: Row, cell: string) => {
    if (cell === 'hasJson') {
      const selectedRow = histories?.find(history => history.id === row.id);
      if (!selectedRow || !selectedRow.hasJson) return;

      setSelectedError({
        request: isValidJSON(selectedRow.request)
          ? JSON.stringify(JSON.parse(selectedRow.request), null, 4)
          : selectedRow.request,
        response: isValidJSON(selectedRow.response)
          ? JSON.stringify(JSON.parse(selectedRow.response), null, 4)
          : selectedRow.response,
        createdAt: selectedRow.createdAt,
      });
    }
  };

  return (
    <>
      {error ? (
        '오류 내역을 불러오지 못했습니다'
      ) : (
        <>
          <HelmetProvider>
            <Helmet>
              <title>케타포 GMS - 포장 오류내역 조회</title>
            </Helmet>
          </HelmetProvider>
          <PopupTemplate
            title="포장 오류내역 조회"
            info={
              <>
                <Typography>배송ID</Typography>
                <Typography sx={{ fontWeight: 700, color: COLORS.ktown4u }}>
                  {packing?.deliverySeq}
                </Typography>
              </>
            }
            subTitle="포장 오류내역 목록"
            main={
              <ListTable
                hideFooter={true}
                columns={columns}
                rows={histories ?? []}
                onClickCell={handleClickCell}
              />
            }
            footer={
              <Button variant="outlined" sx={{ width: '75px' }} onClick={() => window.close()}>
                닫기
              </Button>
            }
          />
          <FormModal
            open={!!selectedError}
            title={'운송사 API 연동 JSON 조회'}
            onClose={() => setSelectedError(undefined)}
          >
            <ErrorJsonModal
              id={id}
              selected={selectedError}
              onClose={() => setSelectedError(undefined)}
            />
          </FormModal>
        </>
      )}
    </>
  );
};

export default PackingErrors;

const columns = [
  {
    headerName: 'NO',
    field: 'order',
    width: 20,
  },
  {
    headerName: '오류 케이스',
    field: 'errorCode',
    width: 100,
  },
  {
    headerName: '오류내역 기록',
    field: 'errorMessage',
    width: 120,
  },
  {
    headerName: '오류 발생 일시',
    field: 'createdAt',
    width: 80,
  },
  {
    headerName: 'JSON',
    field: 'hasJson',
    width: 80,
    renderCell: (value: Value) => (value ? <Link>JSON</Link> : <></>),
  },
];
