import type { GridColDef } from '@mui/x-data-grid-pro';
import { useRecoilValue } from 'recoil';
import { convertCarrierId } from '../../../../libs/common/convertCarrier';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import { carriersAtom } from '../../../../store/outbound/carrier.recoil';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { refCodeOptionsAtom } from '../../../../store/outbound/refCode.recoil';
import { numberFormatter } from '../../../../libs/common/unitConverters';

const gridColumns = () => {
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const carriers = useRecoilValue(carriersAtom);
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const shippingStatusOptions = refCodeOptions?.shippingStatus || [];
  const pickingGroupTypeOptions = refCodeOptions?.pickingGroupType || [];
  const salesTypeByShopOptions = refCodeOptions?.salesTypeByShop || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'status',
      headerName: '출고상태',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        shippingStatusOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'shippingBaseAt',
      headerName: '출고기준일시',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'boxCount',
      headerName: '박스',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => (value ? value : ''),
    },
    {
      field: 'shippingSerialNumber',
      headerName: '출고ID',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliverySeq',
      headerName: '배송ID',
      minWidth: 240,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deliveryId',
      headerName: '배송번호',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isStock',
      headerName: '재고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => (value ? 'Y' : 'N'),
    },
    {
      field: 'skuCount',
      headerName: '품목수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'totalQuantity',
      headerName: '아이템수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'packingHistory',
      headerName: '포장오류내역',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isPop',
      headerName: 'POP',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'trackingNumber',
      headerName: '송장번호',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'carrierId',
      headerName: '배송방식',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertCarrierId(value, carriers);
      },
    },
    {
      field: 'countryCode',
      headerName: '배송국가',
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'salesTypeByShop',
      headerName: '매출구분',
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        salesTypeByShopOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'shippingOrderSerialNumber',
      headerName: '출고지시ID',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pickingGroupType',
      headerName: '집품구분',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) =>
        pickingGroupTypeOptions?.find(v => v.value === value)?.displayName,
    },
    {
      field: 'pickingGroupSerialNumber',
      headerName: '집품그룹ID',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'isInspectionSkipped',
      headerName: '검수필요여부',
      minWidth: 40,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return value ? 'N' : 'Y';
      },
    },
    {
      field: 'estimatedWeight',
      headerName: '포장예상중량무게(g)',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'estimatedVolumeWeight',
      headerName: '포장예상부피무게(g)',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'weight',
      headerName: '포장실제중량무게(g)',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'volumeWeight',
      headerName: '포장실제부피무게(g)',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => numberFormatter(value),
    },
    {
      field: 'shippingCompletedAt',
      headerName: '출고완료일시',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
