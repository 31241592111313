import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiTableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { styled, SxProps } from '@mui/material/styles';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { COLORS } from '../../../../../consts/common/colors';
import FormModal from '../../../../../components/common/FormModal';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import ImageZoomModal from '../../../../../components/ImageZoomModal';

type Tote = {
  toteBarcode: string;
  totalSkuCount: number;
  totalQuantity: number;
};

type ShippingItem = {
  itemId: number;
  itemName: string;
  code: string;
  imageUrl: string;
  barcode: number;
  offlineBarcode: number;
  remark: string;
  orderQuantity: number;
};

type AdminCommentsByShipping = {
  adminComment: string;
  deliveryId: number;
  shippingSerialNumber: string;
};

export type PackingInfo = {
  pickingGroupSerialNumber: string;
  sameShippingCount: number;
  totalWeightOfShipping: number;
  totes: Tote[];
  shippingItems: ShippingItem[];
  pickingGroupType: string;
  adminCommentsByShipping: AdminCommentsByShipping[];
  isCreatedByAdmin: boolean;
};

type Props = {
  info: PackingInfo | undefined;
  onClose: () => void;
};

const PackingInfoModal = ({ info, onClose }: Props) => {
  if (!info) return <></>;

  const isDonation = info.pickingGroupType?.toLowerCase() === 'donation';
  const isBySales = info.isCreatedByAdmin;

  const title = isDonation
    ? isBySales
      ? '기증포장(영업주문) 정보 조회'
      : '기증포장(일반주문) 정보 조회'
    : '대량포장 정보 조회';

  const [enlargedImageUrl, setEnlargedImageUrl] = useState('');

  return (
    <FormModal open={!!info} title={title} onClose={onClose}>
      <Box width={800} height={'80vh'} overflow={'auto'}>
        <SubTitle text={'집품그룹 정보'} />
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
            <TableBody>
              <TableRow>
                <HeadTableCell>집품그룹ID</HeadTableCell>
                <BodyTableCell colSpan={3}>{info.pickingGroupSerialNumber}</BodyTableCell>
              </TableRow>
              <TableRow>
                <HeadTableCell>출고건수</HeadTableCell>
                <BodyTableCell>{info.sameShippingCount?.toLocaleString()}</BodyTableCell>
                <HeadTableCell>{isDonation ? '전체' : '1박스'} 실제중량무게(g)</HeadTableCell>
                <BodyTableCell>{info.totalWeightOfShipping?.toLocaleString()}</BodyTableCell>
              </TableRow>
              <TableRow>
                <HeadTableCell>전체 품목수량</HeadTableCell>
                <BodyTableCell>{calculateSkuQuantity(info)}</BodyTableCell>
                <HeadTableCell>전체 아이템수량</HeadTableCell>
                <BodyTableCell>{calculateTotalItemQuantity(info, isDonation)}</BodyTableCell>
              </TableRow>
              {isDonation && isBySales && (
                <>
                  <TableRow>
                    <HeadTableCell>출고ID</HeadTableCell>
                    <BodyTableCell colSpan={3}>
                      {info.adminCommentsByShipping
                        .map(comment => comment.shippingSerialNumber)
                        .join(', ')}
                    </BodyTableCell>
                  </TableRow>
                  <TableRow>
                    <HeadTableCell>작업지시서</HeadTableCell>
                    <BodyTableCell colSpan={3} sx={{ whiteSpace: 'pre-wrap' }}>
                      {info.adminCommentsByShipping.map(comment => (
                        <Box mb={1.4}>
                          <Typography fontSize={13} fontWeight="bold">
                            {comment.shippingSerialNumber}
                          </Typography>
                          <Typography fontSize={13} ml={1}>
                            {comment.adminComment}
                          </Typography>
                        </Box>
                      ))}
                    </BodyTableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SubTitle text={'집품그룹단위 토트정보'} />
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
            <TableHead sx={{ backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell>{'NO'}</TableCell>
                <TableCell>{'토트'}</TableCell>
                <TableCell>{'품목수량'}</TableCell>
                <TableCell>{'아이템수량'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.totes?.map((tote: Tote, index: number) => (
                <TableRow key={tote.toteBarcode}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{tote.toteBarcode}</TableCell>
                  <TableCell>{tote.totalSkuCount?.toLocaleString()}</TableCell>
                  <TableCell>{tote.totalQuantity?.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SubTitle text={`${isDonation ? '집품그룹단위' : '1박스 포장단위'} 아이템정보`} />
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
            <TableHead sx={{ backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell width="10%">{'NO'}</TableCell>
                <TableCell width="10%">{'바코드'}</TableCell>
                <TableCell width="10%">{'아이템코드'}</TableCell>
                <TableCell width="10%">{'이미지'}</TableCell>
                <TableCell width="50%">{'아이템명'}</TableCell>
                <TableCell width="10%" sx={{ whiteSpace: 'pre-wrap' }}>
                  {isDonation ? '아이템\n총수량' : '아이템수량'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.shippingItems?.map((item: ShippingItem, index: number) => (
                <TableRow key={item.itemId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.offlineBarcode || item.barcode}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>
                    <Box
                      onClick={() => setEnlargedImageUrl(getFinalUrl(item.imageUrl))}
                      sx={{ cursor: 'pointer' }}
                    >
                      <AutoContainImage src={getFinalUrl(item.imageUrl)} imageContainerSize={50} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.itemName}
                    {item.remark && (
                      <span style={{ color: COLORS.red }}>{`=> ${item.remark}`}</span>
                    )}
                  </TableCell>
                  <TableCell>{item.orderQuantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack flexDirection={'row'} justifyContent={'center'} gap={1} m={1}>
          <Button variant={'outlined'} onClick={onClose}>
            닫기
          </Button>
        </Stack>
        <ImageZoomModal imageUrl={enlargedImageUrl} onClose={() => setEnlargedImageUrl('')} />
      </Box>
    </FormModal>
  );
};

export default PackingInfoModal;

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.bgLight,
    color: theme.palette.common.black,
    fontSize: 13,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textAlign: 'center',
  },
  height: 36,
}));

const HeadTableCell = styled(MuiTableCell)(({ theme, width }) => ({
  backgroundColor: COLORS.bgLight,
  color: theme.palette.common.black,
  fontSize: 13,
  width: width || 200,
  height: 36,
}));

const BodyTableCell = styled(MuiTableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 13,
  height: 36,
}));

const calculateSkuQuantity = (info: PackingInfo) => {
  return info.shippingItems?.length;
};

const calculateTotalItemQuantity = (info: PackingInfo, isDonation: boolean) => {
  if (!Array.isArray(info.shippingItems) || isNaN(info.sameShippingCount)) return;

  if (isDonation) {
    return info.shippingItems.reduce((acc, curr) => acc + curr.orderQuantity, 0).toLocaleString();
  }

  return (
    info.shippingItems.reduce((acc, curr) => acc + curr.orderQuantity, 0) * info.sameShippingCount
  ).toLocaleString();
};

const SubTitle = ({ text }: { text: string }) => {
  return (
    <Typography variant="h6" sx={{ color: 'text.primary', mt: 2 }}>
      {text}
    </Typography>
  );
};
