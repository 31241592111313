import { useState } from 'react';
import _ from 'lodash';
import { SearchForm } from '../../../../../../types/form';
import { defaultOption } from '../../../../../../consts/common/defaultOption';

const useCompanySearch = () => {
  const [form, setForm] = useState<SearchForm>({});

  const handleSearch = () => {
    return _.omitBy(form, o => o === defaultOption.value);
  };

  return {
    updateForm: setForm,
    handleSearch,
    getFormForTest: () => form,
  };
};

export default useCompanySearch;
