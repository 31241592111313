import { AxiosError } from 'axios';
import fetcher, { axiosV2 } from 'libs/common/fetcher';
import { InspectionError, InspectionItems } from 'types/outbound';
import { errorType } from '../../../../../types/common';
import { datadogRum } from '@datadog/browser-rum';

type InspectionItem = {
  barcode: string;
  itemId: number;
  inspectionQuantity: number;
};

export type HistoryParams = {
  inspectionId: number;
  inspectionItems: InspectionItem[];
  historyType: InspectionError | null;
  historyDescription: string;
};

export type refundErrorHistoryParams = {
  inspectionId: number;
  historyType: string;
  historyDescription: string;
};

export const createRefundErrorInspectionHistory = async (params: refundErrorHistoryParams) => {
  const { inspectionId, historyType, historyDescription } = params;
  try {
    return await axiosV2.post(`/inspections/histories`, {
      inspectionId,
      historyType,
      historyDescription,
    });
  } catch (error) {
    return (error as AxiosError<errorType, string>).response;
  }
};

export const createInspectionHistory = async (params: HistoryParams) => {
  const { inspectionId, inspectionItems, historyType, historyDescription } = params;

  try {
    return await axiosV2.post(`/inspections/histories`, {
      inspectionId,
      inspectionItems,
      historyType,
      historyDescription,
    });
  } catch (error) {
    return (error as AxiosError<errorType, string>).response;
  }
};

export const fetchWrongPickedItem = async (barcode: string) => {
  try {
    return await fetcher(`/inspections/items/${barcode}`);
  } catch (error) {
    return (error as AxiosError<errorType, string>).response;
  }
};

type UpdateParams = {
  inspectionId: number;
  inspectionItems: InspectionItems[];
};

export const updateCompletedInspection = async (params: UpdateParams) => {
  const { inspectionId, inspectionItems } = params;

  const requestBody = inspectionItems.map(item => ({
    shippingItemId: item.shippingItemId,
    itemId: item.itemId,
    inspectionQuantity: item.inspectedQuantity,
  }));

  try {
    return await axiosV2.patch(`/inspections/${inspectionId}`, {
      inspectionItems: requestBody,
    });
  } catch (error) {
    return (error as AxiosError<errorType, string>).response;
  }
};

export const finishInspection = async (barcode: string) => {
  try {
    return await axiosV2.post(`/inspections/bins/${barcode}`);
  } catch (error) {
    return (error as AxiosError<errorType, string>).response;
  }
};

type ToteParams = {
  toteBarcode: string;
  newToteBarcode: string;
};

export const handleFetchCombineTotes = async (params: ToteParams) => {
  const { toteBarcode, newToteBarcode } = params;

  try {
    return await axiosV2.patch(`/inspections/${toteBarcode}/combine-totes`, {
      newToteBarcode,
    });
  } catch (error) {
    const error1 = error as AxiosError<errorType, string>;
    datadogRum.addAction(`토트합치기 실패 : ${error1.response?.data}`);
    return error1.response;
  }
};
